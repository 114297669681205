import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Grid, TextField, Typography } from "@mui/material";
import React, { Suspense, useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
	ConnectorAPIService,
	EventLogAPIService,
} from "../../../config/api-service";
import { APP_KEY } from "../../../config/constants";
import { useAppContext } from "../../../context/app.context";
import Dialog from "../../common/dialog";
import { usePipelineContext } from "../pipeline.context";
import ConnectorHolder from "./ConnectorHolder";
import ConnectorRequest from "./ConnectorRequest";

const ConnectorApp = React.lazy(() => import("./ConnectorApp"));

export const ChooseConnectorComponent = () => {
	const { connectors } = usePipelineContext();
	const { anonymousUserEmail, setAnonymousUserEmail } = useAppContext();

	const [sourceConnectors, setSourceConnectors] = useState([]);
	const [destinationConnectors, setDestinationConnectors] = useState([]);
	const [showRequestConnectorModal, setShowRequestConnectorModal] =
		useState(false);
	const [requestedConnectorName, setRequestedConnectorName] = useState("");
	const [requestedConnectorDsc, setRequestedConnectorDsc] = useState("");
	const [requestedConnectorNameError, setRequestedConnectorNameError] =
		useState("");
	const [errorMessage, setErrorMessage] = useState("");
	const [loading, setLoading] = useState(false);
	const [currentSourceSearchText, setCurrentSourceSearchText] = useState("");
	const [currentDestinationSearchText, setCurrentDestinationSearchText] =
		useState("");

	const [emailError, setEmailError] = useState("");
	const emailReg = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+.[a-zA-Z0-9+.]$/;
	const [userInfo, setUserInfo] = useState({});
	useEffect(() => {
		const localStorageData = localStorage.getItem(APP_KEY);
		if (localStorageData) {
			setUserInfo(JSON.parse(localStorageData));
		}
		window.addEventListener("anonymousUserEventChange", () => {
			const localStorageUserData = localStorage.getItem(APP_KEY);
			if (localStorageUserData) {
				setUserInfo(JSON.parse(localStorageUserData));
			}
		});
	}, []);

	useEffect(() => {
		const sc = connectors.filter((con) => con.SourceFLG);
		const dc = connectors.filter((con) => con.ConnectorTypeDestinationFlag);

		setSourceConnectors(connectors);
		setFilteredSource(sc);
		setDestinationConnectors(dc);
		setFilteredDestination(dc);
	}, [connectors]);

	const [filteredSource, setFilteredSource] = useState([]);
	const handleSourceSearch = (e) => {
		const searchValue = e.target.value.toLowerCase();
		setCurrentSourceSearchText(searchValue);
		const filteredResults = sourceConnectors.filter(({ ConnectorNM }) =>
			ConnectorNM.toLowerCase().includes(searchValue)
		);
		setFilteredSource(filteredResults);
	};

	const [filteredDestination, setFilteredDestination] = useState([]);
	const handleDestinationSearch = (e) => {
		const searchValue = e.target.value.toLowerCase();
		setCurrentDestinationSearchText(searchValue);
		const filteredResults = destinationConnectors.filter(
			({ ConnectorNM }) => ConnectorNM.toLowerCase().includes(searchValue)
		);
		setFilteredDestination(filteredResults);
	};
	const handleClose = () => {
		setShowRequestConnectorModal(false);
		setEmailError("");
		setRequestedConnectorNameError("");
		setRequestedConnectorName("");
		setRequestedConnectorDsc("");
		setErrorMessage("");
	};

	const handleSubmitConnectorRequest = async () => {
		setErrorMessage("");
		setLoading(true);
		if (userInfo?.user?.UserAnonymousFlg && !anonymousUserEmail) {
			setEmailError("Email address is required");
			setLoading(false);
			return;
		} else if (
			userInfo?.user?.UserAnonymousFlg &&
			!emailReg.test(anonymousUserEmail)
		) {
			setLoading(false);
			return;
		}
		if (!requestedConnectorName) {
			setRequestedConnectorNameError("Connector Name is required");
			setLoading(false);
			return;
		} else {
			const { success } =
				await ConnectorAPIService.createConnectorRequest({
					ConnectorNM: requestedConnectorName,
					ConnectorDSC: requestedConnectorDsc,
					UserID: JSON.parse(localStorage.getItem(APP_KEY)).user
						?.UserID,
					OrganizationID: JSON.parse(localStorage.getItem(APP_KEY))
						.user?.OrganizationID,
					username: `${
						JSON.parse(localStorage.getItem(APP_KEY)).user?.FirstNM
					} ${
						JSON.parse(localStorage.getItem(APP_KEY)).user?.LastNM
					}`,
					email:
						anonymousUserEmail ||
						JSON.parse(localStorage.getItem(APP_KEY)).user
							?.EmailTXT,
				});

			if (success) {
				setLoading(false);
				setShowRequestConnectorModal(false);
				setEmailError("");
				setRequestedConnectorNameError("");
				setRequestedConnectorName("");
				setRequestedConnectorDsc("");
				toast.success(
					"Your connector request was submitted successfully!"
				);
			} else {
				setLoading(false);
				setErrorMessage("Something went wrong!");
				toast.error("Something went wrong!");
			}
		}
	};

	const handleSearchLog = (destination = false) => {
		if (destination) {
			if (
				currentDestinationSearchText &&
				currentDestinationSearchText !== ""
			) {
				let eventMessage = `User searched connectors for term '${currentDestinationSearchText}'`;
				EventLogAPIService.postEvent(eventMessage, null);
			}
		} else {
			if (currentSourceSearchText && currentSourceSearchText !== "") {
				let eventMessage = `User searched connectors for term '${currentSourceSearchText}'`;
				EventLogAPIService.postEvent(eventMessage, null);
			}
		}
	};
	return (
		<>
			{/* CONNECTOR HOLDER COMPONENT */}
			<ConnectorHolder />

			<Grid
				sx={{
					maxWidth: "95%",
					mx: "auto",
					justifyContent: "space-around",
				}}
				container
				spacing={1}
			>
				<Grid item xs={5}>
					<Box
						sx={
							{
								// display: "flex",
								// flexDirection: "column",
								// alignItems: "center",
								// justifyContent: "center",
							}
						}
					>
						<Box sx={{ width: 1, pt: 2, pb: 1, pl: 0.6 }}>
							<TextField
								placeholder="Search Sources"
								size="small"
								variant="outlined"
								onBlur={() => handleSearchLog()}
								onChange={handleSourceSearch}
							/>
							<Box
								className="horizontal-timeline"
								sx={{
									"::before": {
										width: {
											xs: "28%",
											sm: "35%",
											left: "-4px",
										},
									},
									"::after": {
										width: {
											xs: "28%",
											sm: "35%",
											right: "-13px",
										},
									},
								}}
							>
								SOURCES
							</Box>
						</Box>

						<Suspense fallback={<></>}>
							<Box
								sx={{
									textAlign: "left",
								}}
							>
								{filteredSource.map((app) => (
									<ConnectorApp
										key={app.ConnectorID}
										app={app}
										sourceActive={true}
									/>
								))}
							</Box>
						</Suspense>
					</Box>

					{!filteredSource.length && (
						<ConnectorRequest
							searchText={currentSourceSearchText}
						/>
					)}
				</Grid>
				{/* <Grid item xs={2} sx={{ textAlign: "center", mx: "auto", mt: 10 }}>
                    <Box sx={{ width: { xs: 50, sm: 120, md: 140 }, height: { xs: 42, sm: 80, md: 93 }, borderRadius: "8px", mx: "auto", ":hover": { cursor: "pointer" }, border: "1px dashed #000" }} onClick={(e) => setShowRequestConnectorModal(true)}>
                        <Box sx={{ pt: { sm: 1 } }}>
                            <AddIcon sx={{ fontSize: { xs: "11px", sm: "16px" } }} />
                        </Box>
                        <Typography sx={{ fontSize: { xs: "10px", sm: "16px" }, lineHeight: { xs: 0.7, sm: 1, md: 1.5 } }}>Request a connector</Typography>
                    </Box>
                </Grid> */}
				<Grid item xs={5}>
					<Box
						sx={
							{
								// display: "flex",
								// flexDirection: "column",
								// alignItems: "center",
								// justifyContent: "center",
							}
						}
					>
						<Box
							sx={{
								width: 1,
								pt: 2,
								pb: 1,
								pr: 0.6,
								display: "flex",
								flexFlow: "column",
								alignItems: "flex-end",
							}}
						>
							<TextField
								placeholder="Search Destinations"
								size="small"
								variant="outlined"
								onBlur={() => handleSearchLog(true)}
								onChange={handleDestinationSearch}
							/>
							<Box
								className="horizontal-timeline"
								sx={{
									"::before": {
										width: { xs: "17%", sm: "35%" },
										left: "-17px",
									},
									"::after": {
										width: { xs: "17%", sm: "35%" },
									},
								}}
							>
								DESTINATIONS
							</Box>
						</Box>
						<Suspense fallback={<></>}>
							<Box
								sx={{
									textAlign: "right",
									// marginLeft: "48px",
								}}
							>
								{filteredDestination.map((app) => (
									<ConnectorApp
										key={app.ConnectorID}
										app={app}
										sourceActive={false}
									/>
								))}
							</Box>
						</Suspense>
					</Box>

					{!filteredDestination.length && (
						<ConnectorRequest
							searchText={currentDestinationSearchText}
							destination={true}
						/>
					)}
				</Grid>
			</Grid>
			<Dialog
				open={showRequestConnectorModal}
				handleClose={handleClose}
				title="Connector Request"
				centerAligned={false}
			>
				<Box>
					{userInfo?.user?.UserAnonymousFlg && (
						<TextField
							sx={{ mb: 2 }}
							variant="outlined"
							size="small"
							fullWidth
							type="email"
							value={anonymousUserEmail || ""}
							onChange={(e) => {
								// setEmail(e.target.value);
								setAnonymousUserEmail(e.target.value);
								setEmailError("");
							}}
							name="email"
							error={
								emailError !== "" ||
								(!emailReg.test(anonymousUserEmail) &&
									anonymousUserEmail !== "")
							}
							label={
								emailError
									? emailError
									: !emailReg.test(anonymousUserEmail) &&
									  anonymousUserEmail !== ""
									? "Invalid Email!"
									: "Email"
							}
						/>
					)}
					<TextField
						variant="outlined"
						error={requestedConnectorNameError !== ""}
						label={
							requestedConnectorNameError
								? requestedConnectorNameError
								: "Connector Name"
						}
						fullWidth
						value={requestedConnectorName}
						onChange={(e) => {
							setRequestedConnectorName(e.target.value);
							setRequestedConnectorNameError("");
						}}
					/>
					<TextField
						variant="outlined"
						label="Connector Description"
						fullWidth
						multiline
						minRows={4}
						sx={{ mt: 2 }}
						value={requestedConnectorDsc}
						onChange={(e) =>
							setRequestedConnectorDsc(e.target.value)
						}
					/>
					<Box
						sx={{
							mt: 2,
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
						}}
					>
						<Typography color="red">{errorMessage}</Typography>
						<LoadingButton
							onClick={handleSubmitConnectorRequest}
							loading={loading}
							variant="contained"
						>
							Send
						</LoadingButton>
					</Box>
				</Box>
			</Dialog>
		</>
	);
};
