import {
	Box,
	Button,
	Grid,
	MenuItem,
	Select,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
	TextField,
	Typography,
} from "@mui/material";
import Gif from "../../../assets/loader.gif";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { ConnectorAPIService } from "../../../config/api-service";
import { useConnectorModalContext } from "../connectors.context";
import TestLoader from "./TestLoader";
import "./style.css";

const columns = [
	{
		id: "created_at",
		label: "DATE",
		align: "left",
		format: (value) => {
			return <span>{moment(value).format("YYYY-MM-DD hh:mm A")}</span>;
		},
	},
	{
		id: "test_nm",
		label: "TEST",
		align: "left",
	},
	{
		id: "connector_code_branch_nm",
		label: "BRANCH",
		align: "center",
	},
	// {
	//  id: "test_desc",
	//  label: "DESCRIPTION",
	//  align: "center",
	// },
	{
		id: "jira_task_name",
		label: "JIRA TASK",
		align: "left",
		customFormat: (value) => {
			return (
				<a
					href={value.taskUrl}
					target="_blank"
					rel="noreferrer"
					style={{ textDecoration: "none" }}
				>
					<span style={{ display: "flex", alignItems: "center" }}>
						<span style={{ paddingLeft: "5px" }}>{value.name}</span>
					</span>
				</a>
			);
		},
	},
	// {
	//  id: "assignee_email",
	//  label: "ASSIGNEE",
	//  align: "left",
	// },
	{
		id: "test_log",
		label: "LOG",
		align: "left",
	},
	{
		id: "test_passed_flg",
		label: "RESULT",
		align: "left",
		format: (value) => {
			return (
				<span className={value === 0 ? "fail-color" : ""}>
					{value === 1 ? "Passed" : value === 0 ? "Failed" : null}
				</span>
			);
		},
	},
];

const TestsTab = () => {
	const [testData, setTestData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [inProgress, setInProgress] = useState(0);
	const [queryParams, setQueryParams] = useState({});
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [test, setTest] = useState(null);
	const [branch, setBranch] = useState(null);
	const [result, setResult] = useState(null);
	const [jiraTask, setJiraTask] = useState(null);
	const [assignee, setAssignee] = useState(null);
	const [searchTests, setSearchTests] = useState("");

	const [testOptions, setTestOptions] = useState([]);
	const [branchOptions, setBranchOptions] = useState([]);
	const [jiraTaskOptions, setJiraTaskOptions] = useState([]);
	const [assigneeOptions, setAssigneeOptions] = useState([]);
	const [showtestProgress, setShowtestProgress] = useState(false);
	const [orderBy, setOrderBy] = useState("");
	const [orderDirection, setOrderDirection] = useState("asc");
	const [branchValue, setBranchValue] = useState(null);
	const { selectedConnector, hasActiveTask } = useConnectorModalContext();

	useEffect(() => {
		let isMounted = true;
		const fetchAllData = async () => {
			try {
				const [testResponse, jiraTaskResponse, branchResponse] =
					await Promise.all([
						ConnectorAPIService.getConnectorTest(),
						ConnectorAPIService.getConnectorTestJiraTaskList(
							selectedConnector.ConnectorID
						),

						ConnectorAPIService.getConnectorCodeRepository(
							selectedConnector.ConnectorID
						),
					]);

				if (isMounted) {
					if (testResponse) {
						setTestOptions(testResponse?.connectorTest);
					}
					if (jiraTaskResponse) {
						setJiraTaskOptions(jiraTaskResponse);
					}

					if (branchResponse) {
						setBranchOptions(
							branchResponse.connectorCodeRepositoryList || []
						);
					}
				}
			} catch {}
		};

		fetchAllData();
		return () => {
			isMounted = false;
		};
	}, [selectedConnector.ConnectorID, branchValue]);

	const onChangeFilter = async (target) => {
		//? Handle test filter
		if (target.name === "test") {
			setTest(target.value);
		}
		//? Handle branch filter
		if (target.name === "branch") {
			setBranch(target.value);
		}
		//? Handle result filter
		if (target.name === "result") {
			setResult(target.value);
		}
		//? Handle jira task filter
		if (target.name === "jira_task") {
			setJiraTask(target.value);
		}

		//? Handle search tests filter
		if (target.name === "search_tests") {
			setSearchTests(target.value);
		}
	};

	console.log("test", test);

	const handleClearFilter = async () => {
		setBranch(null);
		setTest(null);
		setResult(null);
		setJiraTask(null);
		setSearchTests("");
		setBranchValue(null);
		setQueryParams((prev) => ({
			...prev,
			connector_code_repository_id:
				branchValue === null
					? branchOptions[0].ConnectorCodeRepositoryID
					: branchValue,
		}));
	};

	useEffect(() => {
		if (test) {
			setQueryParams((prev) => ({ ...prev, connector_test_id: test }));
		} else {
			setQueryParams((prev) => {
				if (prev.connector_test_id) {
					delete prev.connector_test_id;
				}
				return { ...prev };
			});
		}

		if (branchValue) {
			setQueryParams((prevQueryParams) => {
				// Check if 'connector_code_repository_id' exists in the queryParams
				if (
					prevQueryParams.hasOwnProperty(
						"connector_code_repository_id"
					)
				) {
					// Update only the existing 'connector_code_repository_id'
					return {
						...prevQueryParams,
						connector_code_repository_id: branchValue,
					};
				}
				// If it doesn't exist, return the previous queryParams unchanged
				return prevQueryParams;
			});
		} else {
			setQueryParams((prev) => {
				if (prev.ConnectorCodeRepositoryID) {
					delete prev.ConnectorCodeRepositoryID;
				}
				return { ...prev };
			});
		}

		if (result !== null) {
			setQueryParams((prev) => ({ ...prev, test_passed_flg: result }));
		} else {
			setQueryParams((prev) => {
				if (queryParams.hasOwnProperty("test_passed_flg")) {
					delete prev.test_passed_flg;
				}
				return { ...prev };
			});
		}

		if (jiraTask) {
			setQueryParams((prev) => ({ ...prev, jira_task_id: jiraTask }));
		} else {
			setQueryParams((prev) => {
				if (prev.jira_task_id) {
					delete prev.jira_task_id;
				}
				return { ...prev };
			});
		}

		if (searchTests) {
			setQueryParams((prev) => ({ ...prev, test_nm: searchTests }));
		} else {
			setQueryParams((prev) => {
				if (prev.test_nm) {
					delete prev.test_nm;
				}
				return { ...prev };
			});
		}
	}, [
		startDate,
		endDate,
		test,
		branch,
		result,
		jiraTask,
		assignee,
		searchTests,
		branchValue,
	]);

	useEffect(() => {
		if (branchOptions[0]?.ConnectorCodeRepositoryID) {
			setQueryParams((prev) => ({
				...prev,
				connector_code_repository_id:
					branchValue === null
						? branchOptions[0].ConnectorCodeRepositoryID
						: branchValue,
			}));
		}
	}, [branchOptions, branchValue]);

	useEffect(() => {
		if (!queryParams.connector_code_repository_id) {
			return;
		}
		let isMounted = true;

		const getTests = async () => {
			setIsLoading(true);
			try {
				const response = await ConnectorAPIService.getConnectorTests(
					selectedConnector.ConnectorID,
					queryParams
				);
				if (isMounted) {
					setTestData(response || []);
					setIsLoading(false);
				}
			} catch (error) {
				if (isMounted) {
					setIsLoading(false);
				}
			}
		};

		getTests();

		return () => {
			isMounted = false;
		};
	}, [queryParams, selectedConnector.ConnectorID, inProgress]);

	console.log("queryParams", queryParams);

	const handleSortRequest = (columnId) => {
		const isAsc = orderBy === columnId && orderDirection === "asc";
		setOrderDirection(isAsc ? "desc" : "asc");
		setOrderBy(columnId);
	};

	const sortData = (data, comparator) => {
		const stabilizedThis = data.map((el, index) => [el, index]);
		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0]);
			if (order !== 0) return order;
			return a[1] - b[1];
		});
		return stabilizedThis.map((el) => el[0]);
	};

	const getComparator = (orderDirection, columnId) => {
		return orderDirection === "desc"
			? (a, b) => descendingComparator(a, b, columnId)
			: (a, b) => -descendingComparator(a, b, columnId);
	};

	const descendingComparator = (a, b, columnId) => {
		if (b[columnId] < a[columnId]) {
			return -1;
		}
		if (b[columnId] > a[columnId]) {
			return 1;
		}
		return 0;
	};

	const sortedTestData = sortData(
		testData,
		getComparator(orderDirection, orderBy)
	);

	useEffect(() => {
		let intervalId;
		let isTabActive = true;

		const fetchTestResultStatus = async () => {
			try {
				const firstBranchId =
					branchValue === null
						? branchOptions[0]?.ConnectorCodeRepositoryID
						: branchValue;
				if (firstBranchId) {
					const response =
						await ConnectorAPIService.getConnectorTestResultStatus(
							selectedConnector.ConnectorID,
							firstBranchId
						);
					if (response?.inProgress !== undefined) {
						setInProgress(response.inProgress);
					}
				}
			} catch (error) {
				console.error("Error fetching test result status:", error);
			}
		};

		const handleVisibilityChange = () => {
			isTabActive = !document.hidden;
		};

		if (branchOptions[0]?.ConnectorCodeRepositoryID) {
			intervalId = setInterval(() => {
				if (isTabActive && inProgress !== 0) {
					fetchTestResultStatus();
				}
			}, 10000);
		}

		document.addEventListener("visibilitychange", handleVisibilityChange);

		fetchTestResultStatus();

		return () => {
			clearInterval(intervalId);
			document.removeEventListener(
				"visibilitychange",
				handleVisibilityChange
			);
		};
	}, [
		hasActiveTask,
		selectedConnector?.ConnectorID,
		branchOptions,
		branchValue,
		inProgress,
	]);

	const hanldeProgress = () => {
		setShowtestProgress(true);
		setTimeout(() => {
			setShowtestProgress(false);
		}, 5000);
	};

	const onChangeBranch = (value) => {
		console.log("value", value.value);

		setBranchValue(value.value);

		setQueryParams((prevQueryParams) => {
			// Check if 'connector_code_repository_id' exists in the queryParams
			if (
				prevQueryParams.hasOwnProperty("connector_code_repository_id")
			) {
				// Update only the existing 'connector_code_repository_id'
				return {
					...prevQueryParams,
					connector_code_repository_id: value.value,
				};
			}
			// If it doesn't exist, return the previous queryParams unchanged
			return prevQueryParams;
		});
	};

	return (
		<Box>
			<Stack
				direction="row"
				sx={{
					justifyContent: "flex-start",
					alignItems: "center",
					marginBottom: "20px",
				}}
				spacing={2}
			>
				<Button
					sx={{
						background: "#57b2e3",
						color: "#fff",
						fontWeight: "bold",
						fontSize: "16px",
						paddingX: "18px",
						"&:hover": {
							background: "#57b2e3",
						},
						"&.Mui-disabled": {
							background: "#e0e0e0", // Background color for disabled state
							color: "#C1C1C1", // Text color for disabled state
						},
					}}
					disabled={true}
				>
					Stop Test
				</Button>

				<Select
					size="small"
					value={branchValue}
					name="branch"
					onChange={(e) => onChangeBranch(e.target)}
					displayEmpty
					renderValue={
						branchValue !== null
							? undefined
							: () =>
									branchOptions[0]?.ConnectorCodeBranchNM ||
									"Branch"
					}
					sx={{ width: "300px" }}
				>
					<MenuItem value={null}>
						<em>None</em>
					</MenuItem>
					{branchOptions.map((branch) => (
						<MenuItem
							key={branch.ConnectorCodeRepositoryID}
							value={branch.ConnectorCodeRepositoryID}
						>
							{branch.ConnectorCodeBranchNM}
						</MenuItem>
					))}
				</Select>

				{inProgress ? (
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							marginLeft: "20px",
							height: "30px",
						}}
					>
						<img
							src={Gif}
							alt="Loading..."
							style={{
								width: "60px",
								height: "60px",
							}}
						/>
						<Typography
							sx={{
								color: "#57b2e3",
								fontSize: "16px",
							}}
						>
							Test in progress
						</Typography>
					</Box>
				) : (
					""
				)}
			</Stack>

			<Grid
				container
				spacing={1}
				sx={{ justifyContent: "space-between", alignItems: "center" }}
			>
				<Grid item xs={12} md={4} xl={2.4}>
					<Select
						size="small"
						value={test}
						name="test"
						onChange={(e) => onChangeFilter(e.target)}
						displayEmpty
						renderValue={test !== null ? undefined : () => "Test"}
						sx={{ width: "100%" }}
					>
						<MenuItem value={null}>
							<em>None</em>
						</MenuItem>
						{testOptions?.map((test) => (
							<MenuItem
								key={test.ConnectorTestID}
								value={test.ConnectorTestID}
							>
								{test.TestNm}
							</MenuItem>
						))}
					</Select>
				</Grid>

				<Grid item xs={12} md={3} xl={2.4}>
					<Select
						size="small"
						value={result}
						name="result"
						onChange={(e) => onChangeFilter(e.target)}
						displayEmpty
						renderValue={
							result !== null ? undefined : () => "Result"
						}
						sx={{ width: "100%" }}
					>
						<MenuItem value={null}>
							<em>None</em>
						</MenuItem>
						<MenuItem value={1}>
							<em>Pass</em>
						</MenuItem>
						<MenuItem value={0}>
							<em>Fail</em>
						</MenuItem>
					</Select>
				</Grid>
				<Grid item xs={12} md={3} xl={2.4}>
					<Select
						size="small"
						value={jiraTask}
						name="jira_task"
						onChange={(e) => onChangeFilter(e.target)}
						displayEmpty
						renderValue={
							jiraTask !== null ? undefined : () => "Jira Task"
						}
						sx={{ width: "100%" }}
					>
						<MenuItem value={null}>
							<em>None</em>
						</MenuItem>
						{jiraTaskOptions.map((task) => (
							<MenuItem
								key={task.jira_task_id}
								value={task.jira_task_id}
							>
								{task.jira_key_id}: {task.jira_task_name}
							</MenuItem>
						))}
					</Select>
				</Grid>

				<Grid item xs={12} md={3} xl={2.4}>
					<TextField
						size="small"
						label="Search Tests"
						value={searchTests}
						name="search_tests"
						onChange={(e) => onChangeFilter(e.target)}
						displayEmpty
						sx={{ width: "100%" }}
					></TextField>
				</Grid>

				<Grid item xs={12} md={3} xl={1.66}>
					<Button
						variant="outlined"
						sx={{ height: 40, width: "100%" }}
						onClick={handleClearFilter}
					>
						CLEAR FILTERS
					</Button>
				</Grid>
			</Grid>

			<TableContainer sx={{ maxHeight: 300, mt: 1 }}>
				<Table stickyHeader dense table size="small">
					<TableHead>
						<TableRow>
							{columns.map((column) => (
								<TableCell
									sx={{
										backgroundColor: "var(--data-con-blue)",
										color: "#fff",
										fontWeight: 700,
										fontSize: { md: "14px" },
									}}
									key={column.id}
									align={column.align}
									sortDirection={
										orderBy === column.id
											? orderDirection
											: false
									}
								>
									<TableSortLabel
										sx={{
											"&.MuiButtonBase-root": {
												color: "#fff",
												"& .MuiSvgIcon-root": {
													color: "#fff",
												},
											},
										}}
										active={orderBy === column.id}
										direction={
											orderBy === column.id
												? orderDirection
												: "asc"
										}
										onClick={() =>
											handleSortRequest(column.id)
										}
									>
										{column.label}
									</TableSortLabel>
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{isLoading ? (
							<TestLoader />
						) : (
							sortedTestData.map((row) => (
								<TableRow
									hover
									tabIndex={-1}
									key={row.connector_test_id}
									sx={{
										"& .MuiTableCell-root": {
											maxWidth: "250px",
											whiteSpace: "unset",
											borderBottom: "none",
										},
									}}
								>
									{columns.map((column) => {
										const value = row[column.id];
										const taskName =
											row.jira_key_id +
											": " +
											row.jira_task_name;
										const taskUrl = row.task_url;

										return (
											<TableCell
												key={column.id}
												align={column.align}
											>
												{column.format
													? column.format(value)
													: column.customFormat
													? column.customFormat({
															name: taskName,
															taskUrl,
													  })
													: value}
											</TableCell>
										);
									})}
								</TableRow>
							))
						)}
					</TableBody>
				</Table>
			</TableContainer>
			{!testData.length && !isLoading && (
				<Typography
					sx={{ color: "red", textAlign: "center", mt: 2 }}
					variant="body"
					component="p"
				>
					No Tests
				</Typography>
			)}
		</Box>
	);
};

export default TestsTab;
