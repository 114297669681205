import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {
	Box,
	Button,
	Grid,
	MenuItem,
	Select,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
	Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { JiraAPIService } from "../../../config/api-service";
import { useConnectorModalContext } from "../connectors.context";
import TaskLoader from "./TaskLoader";

const statusMapper = {
	Completed: "#00800038",
	QA: "#4766ff30",
	Roadblocked: "#ff000033",
	Backlog: "#80808033",
	Active: "#0000ff33",
	Pending: "#ffa50033",
	"ON HOLD": "#ffff0033",
	"Needs Intervention": "#ff69b433",
	Deployed: "#00ff0033",
};

function getStatusColor(status) {
	return statusMapper[status] || "#00000030";
}

const columns = [
	{
		id: "jira_key_id",
		label: "TASK",
		align: "left",
		customFormat: (value) => {
			return (
				<a
					href={value.taskUrl}
					target="_blank"
					rel="noreferrer"
					style={{ textDecoration: "none" }}
				>
					<span style={{ display: "flex", alignItems: "center" }}>
						<CheckBoxIcon sx={{ color: "var(--data-con-blue)" }} />
						<span style={{ paddingLeft: "5px" }}>{value.name}</span>
					</span>
				</a>
			);
		},
	},
	{
		id: "description",
		label: "DESCRIPTION",
		align: "left",
	},
	{
		id: "status_name",
		label: "STATUS",
		align: "center",
		format: (value) => {
			return (
				<span
					style={{
						backgroundColor: getStatusColor(value),
						fontSize: "11px",
						padding: "3px 8px",
						width: "100%",
						margin: 0,
						display: "inline-block",
						color: getStatusColor(value).slice(0, -2),
					}}
				>
					{value?.toUpperCase()}
				</span>
			);
		},
	},
	{
		id: "assignee_email",
		label: "ASSIGNEE",
		align: "left",
	},
	{
		id: "percentage",
		label: "% COMPLETE",
		align: "left",
	},
];

const TasksTab = ({
	isLoading,
	setIsLoading,
	setTaskCompleted,
	newBuild,
	setNewBuild,
}) => {
	const [task, setTask] = useState(null);
	const [taskOptions, setTaskOptions] = useState([]);
	const [status, setStatus] = useState(null);
	const [statusOptions, setStatusOptions] = useState([]);
	const [assignee, setAssignee] = useState(null);
	const [assigneeOptions, setAssigneeOptions] = useState([]);
	const [taskData, setTaskData] = useState([]);
	const [queryParams, setQueryParams] = useState({});
	const [orderBy, setOrderBy] = useState("");
	const [orderDirection, setOrderDirection] = useState("asc");
	const [tasks, setTasks] = useState([]);

	const { selectedConnector, hasActiveTask, setHasActiveTask } =
		useConnectorModalContext();

	useEffect(() => {
		const getTasks = async (repeated = false) => {
			!repeated && setIsLoading(true);
			try {
				const response = await JiraAPIService.getTasks(
					selectedConnector.ConnectorID
				);
				if (response) {
					setTasks(response);
					const totalTask = response.length;
					let completedTask = 0;
					let hasActive = false;

					if (response.length > 0) {
						setNewBuild(false);
					}

					response.forEach((task) => {
						if (task.status_name === "Completed") {
							completedTask++;
						}
						if (task.status_id === 2) {
							hasActive = true;
						}
					});

					setHasActiveTask(hasActive);
					setTaskCompleted(
						`${completedTask}/${totalTask}(${parseInt(
							(completedTask * 100) / totalTask || 0
						)}%)`
					);

					!newBuild
						? setIsLoading(false)
						: newBuild && !response.length
						? setIsLoading(true)
						: setIsLoading(false);
				}
			} catch (error) {
				setIsLoading(false);
			}
		};

		getTasks();

		let intervalId;

		const pollingInterval = newBuild && tasks.length === 0 ? 5000 : 10000;

		if (hasActiveTask || (newBuild && tasks.length === 0)) {
			intervalId = setInterval(() => {
				getTasks(true);
			}, pollingInterval);
		}

		return () => {
			if (intervalId) {
				clearInterval(intervalId);
			}
		};
	}, [selectedConnector.ConnectorID]);

	useEffect(() => {
		let isMounted = true;
		const fetchAllData = async () => {
			try {
				const [statusResponse, usersResponse] = await Promise.all([
					JiraAPIService.getStatus(),
					JiraAPIService.getUsers(selectedConnector.ConnectorID),
				]);

				if (isMounted) {
					if (statusResponse) {
						setStatusOptions(statusResponse);
					}

					if (usersResponse) {
						setAssigneeOptions(usersResponse);
					}
				}
			} catch (error) {
				if (isMounted) {
					setIsLoading(false);
				}
			}
		};

		fetchAllData();

		return () => {
			isMounted = false;
		};
	}, [selectedConnector.ConnectorID]);

	useEffect(() => {
		if (Object.keys(queryParams).length === 0) {
			setTaskOptions(tasks);
			setTaskData(tasks);
		}
	}, [tasks, queryParams]);

	const handleClearFilter = async () => {
		setTask(null);
		setStatus(null);
		setAssignee(null);
		setQueryParams({});
		const response = await JiraAPIService.getTasks(
			selectedConnector.ConnectorID
		);
		if (response) {
			setTaskData(response);
			setTaskOptions(response);
		}
	};

	const onChangeFilter = async (target) => {
		//? Handle task filter
		if (target.name === "task") {
			setTask(target.value);
		}
		//? Handle status filter
		if (target.name === "status") {
			setStatus(target.value);
		}
		//? Handle assignee filter
		if (target.name === "assignee") {
			setAssignee(target.value);
		}
	};

	useEffect(() => {
		if (task) {
			setQueryParams((prev) => ({ ...prev, TaskID: task }));
		} else {
			setQueryParams((prev) => {
				if (prev.TaskID) {
					delete prev.TaskID;
				}
				return { ...prev };
			});
		}
		if (status) {
			setQueryParams((prev) => ({ ...prev, StatusID: status }));
		} else {
			setQueryParams((prev) => {
				if (prev.StatusID) {
					delete prev.StatusID;
				}
				return { ...prev };
			});
		}
		if (assignee) {
			setQueryParams((prev) => ({ ...prev, AssigneeEmail: assignee }));
		} else {
			setQueryParams((prev) => {
				if (prev.AssigneeEmail) {
					delete prev.AssigneeEmail;
				}
				return { ...prev };
			});
		}
	}, [assignee, status, task]);

	useEffect(() => {
		let isMounted = true;

		const getTasks = async () => {
			if (!newBuild) {
				setIsLoading(true);
			}
			try {
				const response = await JiraAPIService.getTasks(
					selectedConnector.ConnectorID,
					queryParams
				);
				if (isMounted) {
					if (response) {
						setTaskData(response);
					}

					if (!newBuild) {
						setIsLoading(false);
					}
				}
			} catch (error) {
				if (isMounted) {
					setIsLoading(false);
				}
			}
		};

		getTasks();

		return () => {
			isMounted = false;
		};
	}, [queryParams, selectedConnector.ConnectorID]);

	const handleSortRequest = (columnId) => {
		const isAsc = orderBy === columnId && orderDirection === "asc";
		setOrderDirection(isAsc ? "desc" : "asc");
		setOrderBy(columnId);
	};

	const sortData = (data, comparator) => {
		const stabilizedThis = data.map((el, index) => [el, index]);
		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0]);
			if (order !== 0) return order;
			return a[1] - b[1];
		});
		return stabilizedThis.map((el) => el[0]);
	};

	const getComparator = (orderDirection, columnId) => {
		return orderDirection === "desc"
			? (a, b) => descendingComparator(a, b, columnId)
			: (a, b) => -descendingComparator(a, b, columnId);
	};

	const descendingComparator = (a, b, columnId) => {
		if (b[columnId] < a[columnId]) {
			return -1;
		}
		if (b[columnId] > a[columnId]) {
			return 1;
		}
		return 0;
	};

	const sortedTaskData = sortData(
		taskData,
		getComparator(orderDirection, orderBy)
	);

	return (
		<Box>
			<Grid
				container
				spacing={1}
				sx={{ justifyContent: { xs: "center", md: "space-between" } }}
			>
				<Grid item xs={12} sm={9} md={6}>
					<Select
						size="small"
						value={task}
						name="task"
						onChange={(e) => onChangeFilter(e.target)}
						displayEmpty
						renderValue={task !== null ? undefined : () => "Task"}
						sx={{ width: "100%" }}
					>
						<MenuItem value={null}>
							<em>None</em>
						</MenuItem>
						{taskOptions.map((task) => (
							<MenuItem key={task.task_id} value={task.task_id}>
								{task.jira_key_id} : {task.task_name}
							</MenuItem>
						))}
					</Select>
				</Grid>
				<Grid item xs={12} sm={3} md={1.4}>
					<Select
						size="small"
						value={status}
						name="status"
						onChange={(e) => onChangeFilter(e.target)}
						displayEmpty
						renderValue={
							status !== null ? undefined : () => "Status"
						}
						sx={{ width: "100%" }}
					>
						<MenuItem value={null}>
							<em>None</em>
						</MenuItem>
						{statusOptions.map((status) => (
							<MenuItem
								key={status.status_id}
								value={status.status_id}
							>
								{status.name}
							</MenuItem>
						))}
					</Select>
				</Grid>
				<Grid item xs={12} sm={7} md={2.9}>
					<Select
						size="small"
						value={assignee}
						name="assignee"
						onChange={(e) => onChangeFilter(e.target)}
						displayEmpty
						renderValue={
							assignee !== null ? undefined : () => "Assignee"
						}
						sx={{ width: "100%" }}
					>
						<MenuItem value={null}>
							<em>None</em>
						</MenuItem>
						{assigneeOptions.map((assignee) => (
							<MenuItem
								key={assignee.assignee_email}
								value={assignee.assignee_email}
							>
								{assignee.assignee_email}
							</MenuItem>
						))}
					</Select>
				</Grid>

				<Grid item xs={12} sm={5} md={1.7}>
					<Button
						variant="outlined"
						sx={{ height: 40, width: "100%" }}
						onClick={handleClearFilter}
					>
						CLEAR FILTERS
					</Button>
				</Grid>
			</Grid>

			<TableContainer sx={{ maxHeight: 300, mt: 1 }}>
				<Table stickyHeader dense table size="small">
					<TableHead>
						<TableRow>
							{columns.map((column) => (
								<TableCell
									sx={{
										backgroundColor: "var(--data-con-blue)",
										color: "#fff",
										fontWeight: 700,
										fontSize: { md: "14px" },
									}}
									key={column.id}
									align={column.align}
									sortDirection={
										orderBy === column.id
											? orderDirection
											: false
									}
								>
									<TableSortLabel
										sx={{
											"&.MuiButtonBase-root": {
												color: "#fff",
												"& .MuiSvgIcon-root": {
													color: "#fff",
												},
											},
										}}
										active={orderBy === column.id}
										direction={
											orderBy === column.id
												? orderDirection
												: "asc"
										}
										onClick={() =>
											handleSortRequest(column.id)
										}
									>
										{column.label}
									</TableSortLabel>
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{isLoading ? (
							<TaskLoader />
						) : (
							sortedTaskData.map((row) => (
								<TableRow
									hover
									role="checkbox"
									tabIndex={-1}
									key={row.task_id}
									sx={{
										"& .MuiTableCell-root": {
											maxWidth: "250px",
											whiteSpace: "unset",
											borderBottom: "none",
										},
									}}
								>
									{columns.map((column) => {
										const value = row[column.id];
										const taskName = row.task_name;
										const taskUrl = row.task_url;

										return (
											<TableCell
												key={column.id}
												align={column.align}
											>
												{column.format
													? column.format(value)
													: column.customFormat
													? column.customFormat({
															name:
																value +
																": " +
																taskName,
															taskUrl,
													  })
													: value}
											</TableCell>
										);
									})}
								</TableRow>
							))
						)}
					</TableBody>
				</Table>
			</TableContainer>

			{!taskData.length && !isLoading && (
				<Typography
					sx={{ color: "red", textAlign: "center", mt: 2 }}
					variant="body"
					component="p"
				>
					No Tasks
				</Typography>
			)}
		</Box>
	);
};

export default TasksTab;
