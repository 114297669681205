import React, { useEffect, useState, useCallback } from "react";
import {
	Box,
	Button,
	Grid,
	MenuItem,
	Select,
	Tooltip,
	Typography,
} from "@mui/material";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";

import Spinner from "../../../assets/images/app-icons/spinner.svg";
import CodeMirror from "@uiw/react-codemirror";
import { ConnectorAPIService } from "../../../config/api-service";
import { useConnectorModalContext } from "../connectors.context";
import CodeSectionLoader from "./CodeSectionLoader";
import LanguageLoader from "./LanguageLoader";
import { python } from "@codemirror/lang-python";
import { EditorView, Decoration } from "@codemirror/view";
import "./script.css";
import { toast } from "react-toastify";

// import Editor, { DiffEditor, useMonaco, loader } from "@monaco-editor/react";

const ScriptsTab = () => {
	const [branch, setBranch] = useState(null);
	const [branchOptions, setBranchOptions] = useState([]);
	const [isBranchLoading, setIsBranchLoading] = useState(false);
	const [codeSection, setCodeSection] = useState([]);
	const [isCodeSectionLoading, setIsCodeSectionLoading] = useState(false);
	const [previousCode, setPreviousCode] = useState("");
	const [newCode, setNewCode] = useState("");
	const [approveCode, setApproveCode] = useState([]);
	const [copy, setCopy] = useState([]);
	const { selectedConnector } = useConnectorModalContext();
	const [isLoading, setIsLoading] = useState(false);
	const [isDeploying, setIsDeploying] = useState(false);
	const [testStatus, setTestStatus] = useState(null);
	const [isImportsChanged, setIsImportsChanged] = useState(false);
	const [changeList, setChangeList] = useState([]);
	const [initialCodeSection, setInitialCodeSection] = useState([]);
	const [isUpdateDisabled, setIsUpdateDisabled] = useState(true);
	const [errorDetail, setErrorDetails] = useState(null);

	const [decorations, setDecorations] = useState({}); // Track decorations per section

	// Define fetchBranchData

	const fetchBranchData = useCallback(async () => {
		if (!selectedConnector) return;

		setIsCodeSectionLoading(true);
		setIsBranchLoading(true);
		try {
			const branchResponse =
				await ConnectorAPIService.getConnectorCodeRepository(
					selectedConnector.ConnectorID
				);
			const branches = branchResponse?.connectorCodeRepositoryList || [];

			setBranchOptions(branches);

			if (branches.length > 0) {
				const initialBranch = branches[0];
				setBranch(initialBranch);

				const codeResponse =
					await ConnectorAPIService.getConnectorGithubCode(
						initialBranch.ConnectorCodeRepository,
						initialBranch.ConnectorCodeBranchNM
					);

				setCodeSection(codeResponse || []);
				setInitialCodeSection(codeResponse || []);
				setChangeList([]);
				const importsSection = codeResponse.find(
					(section) => section.section === "imports"
				);
				if (importsSection) setPreviousCode(importsSection.code);
			}
		} catch (error) {
			console.error("Failed to load data:", error);
		} finally {
			setIsCodeSectionLoading(false);
			setIsBranchLoading(false);
		}
	}, [selectedConnector]);

	// Use fetchBranchData in useEffect
	useEffect(() => {
		fetchBranchData();
	}, [fetchBranchData]);

	const handleBranchChange = async (e) => {
		setBranch(e.target.value);
		if (!e.target.value?.ConnectorCodeRepositoryID) {
			setBranch("");
			return;
		}
		try {
			setIsCodeSectionLoading(true);
			const response = await ConnectorAPIService.getConnectorGithubCode(
				e.target.value.ConnectorCodeRepository,
				e.target.value.ConnectorCodeBranchNM
			);
			setCodeSection(response || []);
			setInitialCodeSection(response || []);
			setChangeList([]);
			setIsCodeSectionLoading(false);
		} catch (error) {
			console.error("Error loading branch code:", error);
		}
	};

	const handleSaveAndTest = async () => {
		setIsLoading(true);
		setTestStatus(null);

		const url = `${process.env.REACT_APP_API_URL}/api/connector/update-github-code`;
		const codeData = {
			connectorCodeRepositoryId: branch?.ConnectorCodeRepositoryID,
			regions: changeList.map((section) => ({
				regionName: section.section,
				code: section.code || '',
			})),
		};

		try {
			const response = await fetch(url, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(codeData),
				credentials: "include", // Ensures cookies are sent with the request
			});

			// Handle the response
			const data = await response.json();

			if (response.ok) {
				// If the request was successful
				setErrorDetails([]);
				// applyHighlights([]);
				setTestStatus("success");
				fetchBranchData();
				setIsLoading(false);
				toast.success("Successfully code save and test");
			} else {
				// If the response status is not 2xx, handle errors gracefully
				console.error("Error:", data.errors);
				setErrorDetails(data.errors); // Store errors in state

				applyHighlights(data.errors); // Highlight errors in editor
				setIsLoading(false);
				toast.error("Your code has some errors");
				setTestStatus("error");
			}
		} catch (error) {
			// Catch any other errors like network issues or unexpected failures
			console.error("Request failed:", error);
			setTestStatus("error"); // Set error status in case of a failed request
			setIsLoading(false);
			setIsDeploying(false);
		}
	};

	//=========Apply highlight error ===========
	const applyHighlights = (errors) => {

		if (!errors || errors.length === 0) {
			setDecorations({});
			return;
		}

		const updatedDecorations = {};
		errors.forEach((error) => {
			const sectionName = error.regionName;

			if (!updatedDecorations[sectionName]) {
				updatedDecorations[sectionName] = [];
			}

			updatedDecorations[sectionName].push((state) => {
				const totalLines = state.doc.lines;
				const lineIndex = error.lineNumber - 1; 

				// Validate if lineIndex is within valid bounds
				if (lineIndex < 0 || lineIndex >= totalLines) {
					console.warn(
						`Skipping invalid line ${error.lineNumber} in ${totalLines}-line document`
					);
					return null; // Skip invalid lines
				}

				// Highlight only valid lines
				const line = state.doc.line(lineIndex + 1);
				return Decoration.line({
					attributes: {
						class: "highlight-line",
						title: error.errorDetails,
					},
				}).range(line.from);
			});
		});

		// **Filter out null values before applying decorations**
		const filteredDecorations = {};
		Object.keys(updatedDecorations).forEach((section) => {
			filteredDecorations[section] = updatedDecorations[section].filter(
				(decoration) => decoration !== null
			);
		});

		// Apply only valid decorations
		setDecorations(filteredDecorations);
	};

	//=========Render Code Mirror ==============
const renderCodeMirror = (section, index) => {
    const sectionDecorations = decorations[section.section] || [];

    return (
        <CodeMirror
            value={section.code || ""}
            extensions={[
                python(),
                EditorView.decorations.compute([], (state) => {
                    // Filter out invalid decorations
                    const validDecorations = sectionDecorations
                        .map((decorator) => decorator(state))
                        .filter((decoration) => decoration !== null); // Keep only valid decorations

                    return Decoration.set(validDecorations); // Apply only valid highlights
                }),
            ]}
            onChange={(value) => {
                handleCodeChange(value, section.section);
                const updatedCodeSection = codeSection.map((s, i) =>
                    i === index ? { ...s, code: value } : s
                );
                setCodeSection(updatedCodeSection);
            }}
            onUpdate={(update) => {
                if (update.docChanged) {
                    // Process code updates
                    const updatedValue = update.state.doc.toString();

                    update.changes.iterChanges(
                        (fromA, toA, fromB, toB, inserted) => {
                            const updatedLineNumber =
                                update.state.doc.lineAt(fromB).number;
                            console.log("Updated Line:", updatedLineNumber);
                        }
                    );

                    const updatedCodeSection = codeSection.map((s, i) =>
                        i === index ? { ...s, code: updatedValue } : s
                    );
                    setCodeSection(updatedCodeSection);
                }
            }}
        />
    );
};
	//=============END TESTING ERROR============

	const handleDeploy = async () => {
		setIsLoading(true);
		setIsDeploying(true);

		const codeData = {
			connectorCodeRepositoryId: branch?.ConnectorCodeRepositoryID,
		};

		try {
			const response = await ConnectorAPIService.deployGithubCode(
				codeData
			);
			fetchBranchData();

			if (response?.success) {
				setTestStatus("success");
			} else {
				setTestStatus("error");
			}
		} catch (error) {
			console.error("Error during Save & Test:", error);
			setTestStatus("error");
		} finally {
			setIsLoading(false);
			setIsDeploying(false);
		}
	};

	// Function to handle code changes
	const handleCodeChange = (newValue, sectionName) => {
		setErrorDetails([]);
		const updatedCodeSection = codeSection.map((s) =>
			s.section === sectionName ? { ...s, code: newValue } : s
		);

		setCodeSection(updatedCodeSection);

		// Compare with the initial code section
		const originalSection = initialCodeSection.find(
			(sec) => sec.section === sectionName
		);

		if (originalSection && originalSection.code !== newValue) {
			// Add or update changed section in changeList
			const updatedChangeList = [...changeList];
			const changeIndex = updatedChangeList.findIndex(
				(sec) => sec.section === sectionName
			);

			if (changeIndex === -1) {
				updatedChangeList.push({
					section: sectionName,
					code: newValue,
				});
			} else {
				updatedChangeList[changeIndex] = {
					section: sectionName,
					code: newValue,
				};
			}

			setChangeList(updatedChangeList);
		} else {
			// Remove from changeList if reverted to initial state
			const updatedChangeList = changeList.filter(
				(sec) => sec.section !== sectionName
			);

			setChangeList(updatedChangeList);
		}
	};

	// Effect to manage the update button state
	useEffect(() => {
		setIsUpdateDisabled(changeList.length === 0);
	}, [changeList]);

	useEffect(() => {
		if (errorDetail && codeSection.length > 0) {
			// Check if codeSection is populated
			console.log("test success");
			console.log("error: " + errorDetail);

			applyHighlights(errorDetail);
		} else {
			console.log("test failed");
			applyHighlights([]); // Clear highlights if no errors or code
		}
	}, [errorDetail, codeSection]);

	console.log("errorDetail", errorDetail);

	const onConnectorCodeTextUpdate = async (connector_code_id, index) => {
		if (newCode !== previousCode) {
			const response = await ConnectorAPIService.updateConnectorCodeText(
				connector_code_id,
				{ user_connector_code_txt: newCode }
			);
			if (response?.success) {
				const newApproveCode = [...approveCode];
				newApproveCode[index] = 0;
				setApproveCode(newApproveCode);
			}
		}
		const newCopy = [...copy];
		newCopy[index] = false;
		setCopy(newCopy);
		setIsImportsChanged(false);
	};

	return (
		<Box>
			{(isLoading || isDeploying) && (
				<Box
					sx={{
						position: "fixed",
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: "rgba(255, 255, 255, 0.8)",
						zIndex: 1300,
						display: "flex",
						alignItems: "center",
						flexDirection: "column",
						justifyContent: "center",
					}}
				>
					<img src={Spinner} alt="Loading" />
					<Typography
						sx={{
							fontWeight: "bold",
							fontSize: "25px",
							color: "#6CB4EE",
						}}
					>
						{isDeploying
							? "DEPLOYING TO PRODUCTION"
							: "TESTING IN PROGRESS"}
					</Typography>
				</Box>
			)}

			<Grid container spacing={2}>
				{isBranchLoading ? (
					<LanguageLoader />
				) : (
					<Grid
						item
						xs={12}
						md={8}
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "flex-start",
						}}
					>
						<Select
							size="small"
							value={branch || ""}
							onChange={handleBranchChange}
							displayEmpty
							sx={{ minWidth: 200 }}
							renderValue={(selected) =>
								selected
									? selected.ConnectorCodeBranchNM
									: "Branch"
							}
						>
							<MenuItem value="">
								<em>None</em>
							</MenuItem>
							{branchOptions.map((branch) => (
								<MenuItem
									key={branch.ConnectorCodeRepositoryID}
									value={branch}
								>
									{branch.ConnectorCodeBranchNM}
								</MenuItem>
							))}
						</Select>
					</Grid>
				)}
				<Grid
					item
					xs={12}
					md={4}
					sx={{ display: "flex", justifyContent: "flex-end" }}
				>
					{branch && (
						<>
							<Tooltip
								title={
									errorDetail?.length > 0
										? "Can't save code, there are errors."
										: ""
								}
								arrow
								disableHoverListener={errorDetail?.length === 0} // Disable tooltip when no errors
							>
								<span
									style={{
										height: "40px !important",
									}}
								>
									{" "}
									{/* Wrap the button for tooltip on disabled state */}
									<Button
										variant="contained"
										disabled={
											isUpdateDisabled ||
											errorDetail?.length > 0
										}
										sx={{
											backgroundColor:
												!isUpdateDisabled &&
												errorDetail?.length === 0
													? "var(--data-con-blue)"
													: "#eeeeee",
											color:
												!isUpdateDisabled &&
												errorDetail?.length === 0
													? "white"
													: "#424242",
											"&:hover": {
												backgroundColor:
													!isUpdateDisabled &&
													errorDetail?.length === 0
														? "var(--data-con-blue)"
														: "#eeeeee",
											},
											lineHeight: "30px",
											mr: 2,
										}}
										onClick={handleSaveAndTest}
									>
										Save & Test
									</Button>
								</span>
							</Tooltip>

							<Button
								variant="contained"
								disabled={!isUpdateDisabled}
								sx={{
									backgroundColor:
										// testStatus === "success"
										isUpdateDisabled
											? "var(--data-con-blue)"
											: "#eeeeee",
									color:
										// testStatus === "success"
										isUpdateDisabled ? "white" : "#424242",
									"&:hover": {
										backgroundColor:
											// testStatus === "success"
											isUpdateDisabled
												? "var(--data-con-blue)"
												: "#eeeeee",
									},
								}}
								onClick={handleDeploy}
							>
								Deploy to Prod
							</Button>
						</>
					)}
				</Grid>
			</Grid>

			{isCodeSectionLoading ? (
				<CodeSectionLoader />
			) : (
				codeSection.map((section, index) => (
					<Box
						key={section.section}
						sx={{
							border: "thin solid rgba(0, 0, 0, 0.12)",
							borderRadius: "5px",
							padding: 1,
							paddingTop: "20px",
							mt: 3,
							position: "relative",
							fontSize: "1rem",
						}}
					>
						<Typography
							sx={{
								textTransform: "uppercase",
								color: "rgba(0, 0, 0, 0.50)",
								position: "absolute",
								top: "-12px",
								left: "1rem",
								backgroundColor: "white",
								px: "5px",
							}}
						>
							{section.section}
						</Typography>

						<Box sx={{ display: "flex", justifyContent: "end" }}>
							<Button
								sx={{
									fontSize: "12px",
									textTransform: "none",
									color: "rgba(0, 0, 0, 0.50)",
									p: 0,
								}}
								onClick={() => {
									const newCopy = [...copy];
									newCopy[index] = true;
									setCopy(newCopy);
									navigator.clipboard.writeText(
										section.code || ""
									);
								}}
							>
								{copy[index] ? (
									<DoneOutlinedIcon
										sx={{
											fontSize: "14px",
											marginRight: "2px",
										}}
									/>
								) : (
									<ContentCopyRoundedIcon
										sx={{
											fontSize: "14px",
											marginRight: "2px",
										}}
									/>
								)}
								{copy[index] ? "Copied!" : "Copy code"}
							</Button>
						</Box>

						{renderCodeMirror(section, index)}
					</Box>
				))
			)}
		</Box>
	);
};

export default ScriptsTab;
