import DeleteIcon from "@mui/icons-material/Delete";
import { Button, Divider, Grid, IconButton, TextField } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { ConnectorAPIService } from "../../../../config/api-service";
import { useConnectorModalContext } from "../../connectors.context";
import ApplicationDetailsLoader from "./ApplicationDetailsLoader";

const changeColor = (defaultInput, userInput) => {
	return userInput !== null && userInput !== defaultInput;
};

const ApplicationDetails = () => {
	const [applicationDetailsInfo, setApplicationDetailsInfo] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	const { selectedConnector, userInfo } = useConnectorModalContext();

	useEffect(() => {
		setIsLoading(true);
		ConnectorAPIService.getConnectorApplicationDetails(
			selectedConnector.ConnectorID
		)
			.then((response) => {
				setApplicationDetailsInfo({
					...response.connectorApplicationDetails,
					redirect_uri_1: [],
					redirect_uri_2: [],
				});
				setIsLoading(false);
			})
			.catch((error) => {
				setIsLoading(false);
			});
	}, [selectedConnector.ConnectorID, setApplicationDetailsInfo]);

	const updateApplicationDetails = async () => {
		if (applicationDetailsInfo?.ConnectorApplicationDetailsID) {
			try {
				await ConnectorAPIService.updateConnectorApplicationDetails(
					applicationDetailsInfo.ConnectorApplicationDetailsID,
					{
						...applicationDetailsInfo,
						UpdatedByID: userInfo?.user?.UserID,
					}
				);
			} catch {}
		} else {
			const data = {};
			Object.keys(applicationDetailsInfo).forEach((field) => {
				data[field.slice(4)] = applicationDetailsInfo[field];
			});

			const response =
				await ConnectorAPIService.createConnectorApplicationDetails(
					selectedConnector.ConnectorID,
					data
				);

			if (response?.success) {
				ConnectorAPIService.getConnectorApplicationDetails(
					selectedConnector.ConnectorID
				)
					.then((response) => {
						setApplicationDetailsInfo({
							...response.connectorApplicationDetails,
							redirect_uri_1: [],
							redirect_uri_2: [],
						});
					})
					.catch((error) => {});
			}
		}
	};

	const handleAddNewRedirectURI = (field) => {
		setApplicationDetailsInfo((prev) => ({
			...prev,
			[field]: [...prev[field], ""],
		}));
	};

	const handleRemoveRedirectURI = (index, field) => {
		const newUri = { ...applicationDetailsInfo };
		newUri[field].splice(index, 1);
		setApplicationDetailsInfo(newUri);
	};

	const changeRedirectURI = (event, index, field) => {
		setApplicationDetailsInfo((prev) => {
			prev[field][index] = event.target.value;
			return { ...prev };
		});
	};

	return (
		<>
			<Divider
				sx={{
					textTransform: "uppercase",
					fontSize: { xs: "14px", sm: "17px", md: "19.2px" },
				}}
			>
				Application Details
			</Divider>
			{isLoading ? (
				<ApplicationDetailsLoader />
			) : (
				<>
					<Grid container spacing={2} sx={{ py: 4 }}>
						<Grid item xs={12} md={6} sx={{ gap: 1 }}>
							<Grid container spacing={2}>
								<Grid item xs={12} md={6} sx={{ gap: 1 }}>
									<TextField
										label="App Creation URL"
										variant="outlined"
										size="small"
										value={
											applicationDetailsInfo?.UserAppCreationUrlTxt ||
											applicationDetailsInfo?.AppCreationUrlTxt
										}
										onChange={(e) =>
											setApplicationDetailsInfo(
												(prev) => ({
													...prev,
													UserAppCreationUrlTxt:
														e.target.value,
												})
											)
										}
										onBlur={updateApplicationDetails}
										InputLabelProps={{
											shrink: true,
											sx: {
												color:
													changeColor(
														applicationDetailsInfo?.AppCreationUrlTxt,
														applicationDetailsInfo?.UserAppCreationUrlTxt
													) && "var(--data-con-blue)",
											},
										}}
										InputProps={{
											sx: {
												height: 37,
												padding: 1,
											},
											inputProps: {
												sx: {
													padding: 0,
													height: "100%",
													display: "flex",
													alignItems: "center",
													fontSize: "0.875rem",
												},
											},
										}}
										sx={{
											width: "100%",
											padding: 0,
											".MuiOutlinedInput-notchedOutline":
												changeColor(
													applicationDetailsInfo?.AppCreationUrlTxt,
													applicationDetailsInfo?.UserAppCreationUrlTxt
												) && {
													borderColor:
														"var(--data-con-blue)",
												},
										}}
									/>
								</Grid>
								<Grid item xs={12} md={6} sx={{ gap: 1 }}>
									<TextField
										label="App Name"
										variant="outlined"
										size="small"
										value={
											applicationDetailsInfo?.UserAppNameTxt ||
											applicationDetailsInfo?.AppNameTxt
										}
										onChange={(e) =>
											setApplicationDetailsInfo(
												(prev) => ({
													...prev,
													UserAppNameTxt:
														e.target.value,
												})
											)
										}
										onBlur={updateApplicationDetails}
										InputLabelProps={{
											shrink: true,
											sx: {
												color:
													changeColor(
														applicationDetailsInfo?.AppNameTxt,
														applicationDetailsInfo?.UserAppNameTxt
													) && "var(--data-con-blue)",
											},
										}}
										InputProps={{
											sx: {
												height: 37,
												padding: 1,
											},
											inputProps: {
												sx: {
													padding: 0,
													height: "100%",
													display: "flex",
													alignItems: "center",
													fontSize: "0.875rem",
												},
											},
										}}
										sx={{
											width: "100%",
											padding: 0,
											".MuiOutlinedInput-notchedOutline":
												changeColor(
													applicationDetailsInfo?.AppNameTxt,
													applicationDetailsInfo?.UserAppNameTxt
												) && {
													borderColor:
														"var(--data-con-blue)",
												},
										}}
									/>
								</Grid>
								{applicationDetailsInfo?.redirect_uri_1?.map(
									(uri, uriIndex) => (
										<Fragment key={uriIndex}>
											<Grid
												item
												xs={11.2}
												sx={{ gap: 1 }}
											>
												<TextField
													label={`Redirect URI #${
														uriIndex + 1
													}`}
													variant="outlined"
													size="small"
													value={uri}
													onChange={(e) =>
														changeRedirectURI(
															e,
															uriIndex,
															"redirect_uri_1"
														)
													}
													InputLabelProps={{
														shrink: true,
													}}
													InputProps={{
														sx: {
															height: 37,
															padding: 1,
														},
														inputProps: {
															sx: {
																padding: 0,
																height: "100%",
																display: "flex",
																alignItems:
																	"center",
																fontSize:
																	"0.875rem",
															},
														},
													}}
													sx={{
														width: "100%",
														padding: 0,
													}}
												/>
											</Grid>
											<Grid
												item
												xs={0.8}
												sx={{ textAlign: "right" }}
											>
												<IconButton
													disableRipple
													sx={{ px: 0 }}
													aria-label="delete"
													onClick={() =>
														handleRemoveRedirectURI(
															uriIndex,
															"redirect_uri_1"
														)
													}
												>
													<DeleteIcon />
												</IconButton>
											</Grid>
										</Fragment>
									)
								)}
								<Grid
									item
									xs={12}
									md={6}
									sx={{
										gap: 1,
										textAlign: {
											xs: "center",
											md: "unset",
										},
									}}
								>
									<Button
										variant="contained"
										sx={{
											backgroundColor:
												"var(--data-con-blue)",
										}}
										onClick={() =>
											handleAddNewRedirectURI(
												"redirect_uri_1"
											)
										}
									>
										ADD URI
									</Button>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} md={6} sx={{ gap: 1 }}>
							<Grid container spacing={2}>
								<Grid item xs={12} md={6} sx={{ gap: 1 }}>
									<TextField
										label="App Description"
										variant="outlined"
										size="small"
										value={
											applicationDetailsInfo?.UserAppDescriptionTxt ||
											applicationDetailsInfo?.AppDescriptionTxt
										}
										onChange={(e) =>
											setApplicationDetailsInfo(
												(prev) => ({
													...prev,
													UserAppDescriptionTxt:
														e.target.value,
												})
											)
										}
										onBlur={updateApplicationDetails}
										InputLabelProps={{
											shrink: true,
											sx: {
												color:
													changeColor(
														applicationDetailsInfo?.AppDescriptionTxt,
														applicationDetailsInfo?.UserAppDescriptionTxt
													) && "var(--data-con-blue)",
											},
										}}
										InputProps={{
											sx: {
												height: 37,
												padding: 1,
											},
											inputProps: {
												sx: {
													padding: 0,
													height: "100%",
													display: "flex",
													alignItems: "center",
													fontSize: "0.875rem",
												},
											},
										}}
										sx={{
											width: "100%",
											padding: 0,
											".MuiOutlinedInput-notchedOutline":
												changeColor(
													applicationDetailsInfo?.AppDescriptionTxt,
													applicationDetailsInfo?.UserAppDescriptionTxt
												) && {
													borderColor:
														"var(--data-con-blue)",
												},
										}}
									/>
								</Grid>
								<Grid item xs={12} md={6} sx={{ gap: 1 }}>
									<TextField
										label="App Icon URL"
										variant="outlined"
										size="small"
										value={
											applicationDetailsInfo?.UserAppIconUrlTxt ||
											applicationDetailsInfo?.AppIconUrlTxt
										}
										onChange={(e) =>
											setApplicationDetailsInfo(
												(prev) => ({
													...prev,
													UserAppIconUrlTxt:
														e.target.value,
												})
											)
										}
										onBlur={updateApplicationDetails}
										InputLabelProps={{
											shrink: true,
											sx: {
												color:
													changeColor(
														applicationDetailsInfo?.AppIconUrlTxt,
														applicationDetailsInfo?.UserAppIconUrlTxt
													) && "var(--data-con-blue)",
											},
										}}
										InputProps={{
											sx: {
												height: 37,
												padding: 1,
											},
											inputProps: {
												sx: {
													padding: 0,
													height: "100%",
													display: "flex",
													alignItems: "center",
													fontSize: "0.875rem",
												},
											},
										}}
										sx={{
											width: "100%",
											padding: 0,
											".MuiOutlinedInput-notchedOutline":
												changeColor(
													applicationDetailsInfo?.AppIconUrlTxt,
													applicationDetailsInfo?.UserAppIconUrlTxt
												) && {
													borderColor:
														"var(--data-con-blue)",
												},
										}}
									/>
								</Grid>
								{applicationDetailsInfo?.redirect_uri_2?.map(
									(uri, uriIndex) => (
										<Fragment key={uriIndex}>
											<Grid
												item
												xs={11.2}
												sx={{ gap: 1 }}
											>
												<TextField
													label={`Redirect URI #${
														uriIndex + 1
													}`}
													variant="outlined"
													size="small"
													value={uri}
													onChange={(e) =>
														changeRedirectURI(
															e,
															uriIndex,
															"redirect_uri_2"
														)
													}
													InputLabelProps={{
														shrink: true,
													}}
													InputProps={{
														sx: {
															height: 37,
															padding: 1,
														},
														inputProps: {
															sx: {
																padding: 0,
																height: "100%",
																display: "flex",
																alignItems:
																	"center",
																fontSize:
																	"0.875rem",
															},
														},
													}}
													sx={{
														width: "100%",
														padding: 0,
													}}
												/>
											</Grid>
											<Grid
												item
												xs={0.8}
												sx={{ textAlign: "right" }}
											>
												<IconButton
													disableRipple
													sx={{ px: 0 }}
													aria-label="delete"
													onClick={() =>
														handleRemoveRedirectURI(
															uriIndex,
															"redirect_uri_2"
														)
													}
												>
													<DeleteIcon />
												</IconButton>
											</Grid>
										</Fragment>
									)
								)}
								<Grid
									item
									xs={12}
									md={6}
									sx={{
										gap: 1,
										textAlign: {
											xs: "center",
											md: "unset",
										},
									}}
								>
									<Button
										variant="contained"
										sx={{
											backgroundColor:
												"var(--data-con-blue)",
										}}
										onClick={() =>
											handleAddNewRedirectURI(
												"redirect_uri_2"
											)
										}
									>
										ADD URI
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid container spacing={2} sx={{ py: 4 }}>
						<Grid item xs={12} sx={{ gap: 1 }}>
							<TextField
								label="OAuth Scopes Needed"
								variant="outlined"
								multiline
								rows={2}
								value={
									applicationDetailsInfo?.UserOAuthScopesNeededTxt !==
									undefined
										? applicationDetailsInfo.UserOAuthScopesNeededTxt
										: applicationDetailsInfo?.OAuthScopesNeededTxt ||
										  ""
								}
								onChange={(e) =>
									setApplicationDetailsInfo((prev) => ({
										...prev,
										UserOAuthScopesNeededTxt:
											e.target.value,
									}))
								}
								onBlur={updateApplicationDetails}
								InputLabelProps={{
									shrink: true,
									sx: {
										color:
											changeColor(
												applicationDetailsInfo?.OAuthScopesNeededTxt,
												applicationDetailsInfo?.UserOAuthScopesNeededTxt
											) && "var(--data-con-blue)",
									},
								}}
								InputProps={{
									sx: {
										padding: 1,
									},
									inputProps: {
										sx: {
											padding: 0,
											height: "100%",
											display: "flex",
											alignItems: "center",
											fontSize: "0.875rem",
										},
									},
								}}
								sx={{
									width: "100%",
									padding: 0,
									".MuiOutlinedInput-notchedOutline":
										changeColor(
											applicationDetailsInfo?.OAuthScopesNeededTxt,
											applicationDetailsInfo?.UserOAuthScopesNeededTxt
										) && {
											borderColor: "var(--data-con-blue)",
										},
								}}
							/>
						</Grid>
						<Grid item xs={12} sx={{ gap: 1 }}>
							<TextField
								label="App Permissions/Scopes"
								variant="outlined"
								multiline
								rows={2}
								value={
									applicationDetailsInfo?.UserAppPermissionsScopesTxt ||
									applicationDetailsInfo?.AppPermissionsScopesTxt
								}
								onChange={(e) =>
									setApplicationDetailsInfo((prev) => ({
										...prev,
										UserAppPermissionsScopesTxt:
											e.target.value,
									}))
								}
								onBlur={updateApplicationDetails}
								InputLabelProps={{
									shrink: true,
									sx: {
										color:
											changeColor(
												applicationDetailsInfo?.AppPermissionsScopesTxt,
												applicationDetailsInfo?.UserAppPermissionsScopesTxt
											) && "var(--data-con-blue)",
									},
								}}
								InputProps={{
									sx: {
										padding: 1,
									},
									inputProps: {
										sx: {
											padding: 0,
											height: "100%",
											display: "flex",
											alignItems: "center",
											fontSize: "0.875rem",
										},
									},
								}}
								sx={{
									width: "100%",
									padding: 0,
									".MuiOutlinedInput-notchedOutline":
										changeColor(
											applicationDetailsInfo?.AppPermissionsScopesTxt,
											applicationDetailsInfo?.UserAppPermissionsScopesTxt
										) && {
											borderColor: "var(--data-con-blue)",
										},
								}}
							/>
						</Grid>
						<Grid item xs={12} sx={{ gap: 1 }}>
							<TextField
								label="Callback URLs"
								variant="outlined"
								multiline
								rows={2}
								value={
									applicationDetailsInfo?.UserCallbackUrlsTxt ||
									applicationDetailsInfo?.CallbackUrlsTxt
								}
								onChange={(e) =>
									setApplicationDetailsInfo((prev) => ({
										...prev,
										UserCallbackUrlsTxt: e.target.value,
									}))
								}
								onBlur={updateApplicationDetails}
								InputLabelProps={{
									shrink: true,
									sx: {
										color:
											changeColor(
												applicationDetailsInfo?.CallbackUrlsTxt,
												applicationDetailsInfo?.UserCallbackUrlsTxt
											) && "var(--data-con-blue)",
									},
								}}
								InputProps={{
									sx: {
										padding: 1,
									},
									inputProps: {
										sx: {
											padding: 0,
											height: "100%",
											display: "flex",
											alignItems: "center",
											fontSize: "0.875rem",
										},
									},
								}}
								sx={{
									width: "100%",
									padding: 0,
									".MuiOutlinedInput-notchedOutline":
										changeColor(
											applicationDetailsInfo?.CallbackUrlsTxt,
											applicationDetailsInfo?.UserCallbackUrlsTxt
										) && {
											borderColor: "var(--data-con-blue)",
										},
								}}
							/>
						</Grid>
					</Grid>
				</>
			)}
		</>
	);
};

export default ApplicationDetails;
