import { useEffect, useState } from "react";
import {
	Box,
	Divider,
	Button,
	Grid,
	TextField,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	IconButton,
	Skeleton,
	Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useConnectorModalContext } from "../../connectors.context";
import { ConnectorAPIService } from "../../../../config/api-service";
import { toast } from "react-toastify";
import DynamicTestUserForm from "./DynamicTestUserForm";

const TestUserAccounts = () => {
	const [testUserAccountsInfo, setTestUserAccountsInfo] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [authTypes, setAuthTypes] = useState([]);
	const [selectedAuthType, setSelectedAuthType] = useState(""); // Track the selected value
	const [testUserAccData, setTestUserAccData] = useState();
	const [loading, setLoading] = useState(false);
	const [authId, setAuthId] = useState();
	const [authFields, setAuthFields] = useState();
	const [localUserCount, setLocalUserCount] = useState(0); // Local count for new users

	const { selectedConnector, newAuthTypeAdded } = useConnectorModalContext();

	useEffect(() => {
		setIsLoading(true);

		ConnectorAPIService.getConnectorAuthenticationTypes(
			selectedConnector.ConnectorID
		)
			.then((res) => {
				res.authenticationDetailList.sort((a, b) =>
					a.authentication_type_name.localeCompare(
						b.authentication_type_name
					)
				);
				setAuthTypes(res.authenticationDetailList);
				setIsLoading(false);
			})
			.catch(() => {
				setIsLoading(false);
			});
	}, [selectedConnector.ConnectorID, newAuthTypeAdded]);

	const handleAddNewTestUser = async () => {
		try {
			// Fetch the authentication fields
			const res =
				await ConnectorAPIService.getConnectorAuthenticationTypeFields(
					selectedConnector.ConnectorID,
					selectedAuthType
				);

			setAuthFields(res);

			
			setLocalUserCount((prev) => prev + 1 || 1); 
		

			const numbers = testUserAccData.map((item) => {
				const match = item.CredentialNM.match(/\d+/); 
				return match ? parseInt(match[0]) : 1; 
			});

			const maxNumber = numbers.length > 0 ? Math.max(...numbers) + 1 : 1;
			setTestUserAccountsInfo((prev) => [
				...prev,
				{
					connectionName: `Test User #${maxNumber}`, 
					authFields: res?.authenticationTypeFieldList || [], 
				},
			]);
		} catch (error) {
			console.error(
				"Error fetching auth fields or adding new user:",
				error
			);
			toast.error("Failed to add new test user");
		}
	};

	const handleAuthTypeChange = async (e) => {
		const selectedAuthType = e.target.value;
		setSelectedAuthType(selectedAuthType); 

		if (!selectedAuthType) {
			setTestUserAccData([]);
			return;
		}
		setIsLoading(true);

		setAuthId(selectedAuthType);
		try {
			const data = await ConnectorAPIService.getConnectorTestUser(
				selectedConnector.ConnectorID,
				selectedAuthType
			);
			setTestUserAccountsInfo([]);
			setTestUserAccData(data);
		} catch (error) {
			console.error("Error fetching test user data:", error);
			setTestUserAccData([]);
		} finally {
			setIsLoading(false); 
		}
	};

	const handleSave = async (userIndex) => {
		const user = testUserAccountsInfo[userIndex];
		const fieldsData = user.authFields.reduce((acc, field) => {
			acc[field.field_nm] = field.field_value_dsc || "";
			return acc;
		}, {});
		const result = {
			connectionName: user.connectionName, // Add test user number
			...fieldsData, // Spread dynamic fields data
		};
		setLoading(true);

		try {
			const response = await ConnectorAPIService.createConnectorTestUser(
				selectedConnector.ConnectorID,
				authId,
				result
			);
			if (response) {
				toast.success("User created successfully");

				setTestUserAccountsInfo((prev) =>
					prev.filter((_, i) => i !== userIndex)
				);

				const updatedData =
					await ConnectorAPIService.getConnectorTestUser(
						selectedConnector.ConnectorID,
						selectedAuthType
					);

				setTestUserAccData(updatedData);
				setTestUserAccountsInfo((prev) =>
					prev.filter((_, i) => i !== userIndex)
				);
			}
		} catch (error) {
			toast.error("Failed to save user");
			console.error("Error saving user:", error);
		} finally {
			setLoading(false);
		}
	};

	const handleRemoveTestUser = (index) => {
		setTestUserAccountsInfo((prev) => prev.filter((_, i) => i !== index));
		setLocalUserCount((prev) => prev - 1);
	};

	const handleFieldChange = (e, userIndex, fieldIndex) => {
		const { value } = e.target;
		setTestUserAccountsInfo((prev) => {
			const updatedUsers = [...prev];
			updatedUsers[userIndex].authFields[fieldIndex].field_value_dsc =
				value;
			return updatedUsers;
		});
	};

	return (
		<>
			<Divider
				sx={{
					textTransform: "uppercase",
					fontSize: { xs: "14px", sm: "17px", md: "19.2px" },
				}}
			>
				Test User Accounts
			</Divider>
			<Box sx={{ mt: 5, textAlign: "center" }}>
				<FormControl size="small">
					<InputLabel id={`verification_type`}>
						Account Verification Type
					</InputLabel>
					<Select
						labelId={`verification_type`}
						value={selectedAuthType || ""}
						onChange={(e) => handleAuthTypeChange(e)}
						label="Account Verification Type"
						sx={{
							minWidth: { xs: 230, md: 250 },
						}}
					>
						<MenuItem value="">
							<em>None</em>
						</MenuItem>
						{authTypes.map((authType) => (
							<MenuItem
								key={authType.authentication_type_id}
								value={authType.authentication_type_id}
							>
								{authType.authentication_type_name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Box>

			{isLoading ? (
				<Box sx={{ padding: 4 }}>
					<Skeleton
						variant="rectangular"
						height={50}
						sx={{ marginBottom: 2 }}
					/>
					<Skeleton
						variant="rectangular"
						height={50}
						sx={{ marginBottom: 2 }}
					/>
					<Skeleton
						variant="rectangular"
						height={50}
						sx={{ marginBottom: 2 }}
					/>
				</Box>
			) : testUserAccData?.length === 0 ? (
				<Box sx={{ textAlign: "center", marginY: "10px" }}>
					<Typography>You Have no Data</Typography>
				</Box>
			) : (
				<DynamicTestUserForm data={testUserAccData} />
			)}

			{testUserAccountsInfo.map((user, index) => (
				<Box
					key={index}
					sx={{
						border: "1px solid lightgray",
						my: 2,
						borderRadius: "5px",
						position: "relative",
						p: 4,
					}}
				>
					<Box
						sx={{
							position: "absolute",
							top: "-10px",
							left: "16px",
							backgroundColor: "white",
							padding: "0 8px",
							fontSize: "14px",
							fontWeight: "bold",
							color: "gray",
						}}
					>
						{user.connectionName}
					</Box>
					<IconButton
						disableRipple
						sx={{
							position: "absolute",
							top: "-5px",
							right: "-5px",
						}}
						onClick={() => handleRemoveTestUser(index)}
					>
						<DeleteIcon />
					</IconButton>
					<Grid container spacing={2}>
						{user.authFields.map((authField, i) => (
							<Grid
								key={i}
								item
								xs={12}
								md={6}
								sx={{
									gap: 1,
									display: "flex",
									flexDirection: "column",
								}}
							>
								<TextField
									label={authField.field_nm}
									variant="outlined"
									size="small"
									fullWidth
									onChange={(e) =>
										handleFieldChange(e, index, i)
									}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</Grid>
						))}
					</Grid>
					<Box sx={{ textAlign: "center", py: 2 }}>
						<Button
							variant="contained"
							sx={{
								backgroundColor: "var(--data-con-blue)",
							}}
							onClick={() => handleSave(index)}
						>
							Save
						</Button>
					</Box>
				</Box>
			))}

			<Box sx={{ textAlign: "center", py: 4 }}>
				<Button
					variant="contained"
					sx={{
						height: 40,
						minWidth: 150,
						backgroundColor: "var(--data-con-blue)",
					}}
					onClick={handleAddNewTestUser}
					disabled={!selectedAuthType}
				>
					ADD TEST USER
				</Button>
			</Box>
		</>
	);
};

export default TestUserAccounts;
