import React, { useState, useEffect } from 'react';
import { ConnectorAPIService } from "../../../../config/api-service";
import { useConnectorModalContext } from "../../connectors.context";
import { useAlertContext } from "../../../../context/alert.context";
import { Divider, Button, Box } from "@mui/material";

const ConnectorSettings = () => {
    const [isActive, setIsActive] = useState(true);
    const { selectedConnector } = useConnectorModalContext();
    const { successAlert } = useAlertContext();

    useEffect(() => {
        const getConnectorSettings = async () => {
            const response = await ConnectorAPIService.getConnectorSettings(selectedConnector.ConnectorID);
            setIsActive(response?.active);
        };
        getConnectorSettings();
    }, []);

    const toggleConnector = () => {
        ConnectorAPIService.updateConnectorSettings(
            selectedConnector.ConnectorID,
            { active: !isActive }
        ).then(() => {
            setIsActive(!isActive);
        });
    };

    const onResetConnector = () => {
        ConnectorAPIService.resetConnector(
            selectedConnector.ConnectorID,
        ).then(() => {
            successAlert('Connector reset initiated. Please wait until the connector is fully reset and TASKS tab is cleared out until running a rebuild!');
        });
    };

    const onRebuildConnector = () => {
        ConnectorAPIService.buildConnector({
            connector_id: selectedConnector.ConnectorID,
        }).then(() => {
            successAlert('Connector rebuild initiated');
        });
    };

    return (
        <div>
            <Divider
                sx={{
                    textTransform: "uppercase",
                    fontSize: { xs: "14px", sm: "17px", md: "19.2px" },
                }}
            >
                Connector Settings
            </Divider>
            <Box sx={{ textAlign: "center", py: 4 }}>
                <Button
                    variant="contained"
                    sx={{
                        height: 40,
                        minWidth: 150,
                        backgroundColor: "var(--data-con-blue)",
                        marginRight: 2,
                    }}
                    onClick={toggleConnector}
                >
                    {isActive ? 'DEACTIVATE' : 'ACTIVATE'}
                </Button>
                <Button
                    variant="contained"
                    sx={{
                        height: 40,
                        minWidth: 150,
                        marginRight: 2,
                        backgroundColor: "var(--data-con-blue)",
                    }}
                    onClick={onResetConnector}
                >
                    RESET CONNECTOR
                </Button>
                <Button
                    variant="contained"
                    sx={{
                        height: 40,
                        minWidth: 150,
                        backgroundColor: "var(--data-con-blue)",
                    }}
                    onClick={onRebuildConnector}
                >
                    REBUILD CONNECTOR
                </Button>
            </Box>
        </div>
    );
};

export default ConnectorSettings;
