export const APP_KEY = 'DATA_CONNECTOR'
export const ORGANIZATIONS = 'DATA_CON_ORGANIZATIONS'

export const SQL_SERVER_DATATYPES = [
  'char',
  'bigint',
  'binary',
  'bit',
  'cursor',
  'date',
  'datetime',
  'datetime2',
  'datetimeoffset',
  'decimal',
  'float',
  'image',
  'int',
  'money',
  'nchar',
  'ntext',
  'numeric',
  'nvarchar',
  'nvarchar',
  'real',
  'smalldatetime',
  'smallint',
  'smallmoney',
  'sql_variant',
  'table',
  'text',
  'time',
  'timestamp',
  'tinyint',
  'uniqueidentifier',
  'varbinary',
  'varbinary',
  'varchar',
  'xml'
]

export const MONTHS = [
  { id: 0, label: 'January', value: 1 },
  { id: 1, label: 'Febuary', value: 2 },
  { id: 2, label: 'March', value: 3 },
  { id: 3, label: 'April', value: 4 },
  { id: 4, label: 'May', value: 5 },
  { id: 5, label: 'June', value: 6 },
  { id: 6, label: 'July', value: 7 },
  { id: 7, label: 'August', value: 8 },
  { id: 8, label: 'September', value: 9 },
  { id: 9, label: 'October', value: 10 },
  { id: 10, label: 'November', value: 11 },
  { id: 11, label: 'December', value: 12 },
]

export const DAYS = [
  { id: 1, label: 'Sunday', value: 0 },
  { id: 2, label: 'Monday', value: 1 },
  { id: 3, label: 'Tuesday', value: 2 },
  { id: 4, label: 'Wednesday', value: 3 },
  { id: 5, label: 'Thursday', value: 4 },
  { id: 6, label: 'Friday', value: 5 },
  { id: 7, label: 'Saturday', value: 6 },
]

export const PERIOD_DROPDOWN = [
  // { id: 6, label: 'Manual', value: 6 },
  { id: 0, label: 'Hourly', value: 0 },
  { id: 1, label: 'Daily', value: 1 },
  { id: 2, label: 'Every Weekday', value: 2 },
  { id: 3, label: 'Weekly', value: 3 },
  { id: 4, label: 'Monthly', value: 4 },
  { id: 5, label: 'Yearly', value: 5 },
]

export const PERIOD_FORM = [
  {
    id: 0,
    forms: [
      { id: 0, groupID: 0, type: 'number', helperText: 'Enter a minute (0 - 59)' }
    ]
  }
]

export const PIPELINE_STEPS = ['Connect', 'Map', 'Configure'];


export const PERMISSIONS_LIST = [
  {
    "PermissionID": 1,
    "PermissionNM": "Internal Billing",
    "PermissionDSC": null,
    "GroupDSC": null,
    "InternalFLG": 1,
    "ActiveFLG": 1
  },
  {
    "PermissionID": 2,
    "PermissionNM": "Internal Admin",
    "PermissionDSC": null,
    "GroupDSC": null,
    "InternalFLG": 1,
    "ActiveFLG": 1
  },
  {
    "PermissionID": 3,
    "PermissionNM": "Internal Organization",
    "PermissionDSC": null,
    "GroupDSC": null,
    "InternalFLG": 1,
    "ActiveFLG": 1
  },
  {
    "PermissionID": 4,
    "PermissionNM": "Organization",
    "PermissionDSC": "Update organization info",
    "GroupDSC": "Admin",
    "InternalFLG": 0,
    "ActiveFLG": 1
  },
  {
    "PermissionID": 5,
    "PermissionNM": "Billing",
    "PermissionDSC": "Update billing info",
    "GroupDSC": "Admin",
    "InternalFLG": 0,
    "ActiveFLG": 1
  },
  {
    "PermissionID": 6,
    "PermissionNM": "Pipelines",
    "PermissionDSC": "Create/edit pipelines",
    "GroupDSC": "Developer",
    "InternalFLG": 0,
    "ActiveFLG": 1
  },
  {
    "PermissionID": 7,
    "PermissionNM": "Account Pipelines",
    "PermissionDSC": null,
    "GroupDSC": null,
    "InternalFLG": 0,
    "ActiveFLG": 0
  },
  {
    "PermissionID": 8,
    "PermissionNM": "Connections",
    "PermissionDSC": "Create/edit connections",
    "GroupDSC": "Developer",
    "InternalFLG": 0,
    "ActiveFLG": 1
  },
  {
    "PermissionID": 9,
    "PermissionNM": "HIPAA",
    "PermissionDSC": null,
    "GroupDSC": null,
    "InternalFLG": 0,
    "ActiveFLG": 0
  },
  {
    "PermissionID": 10,
    "PermissionNM": "Account",
    "PermissionDSC": null,
    "GroupDSC": null,
    "InternalFLG": 0,
    "ActiveFLG": 0
  },
  {
    "PermissionID": 11,
    "PermissionNM": "Admin",
    "PermissionDSC": "All access",
    "GroupDSC": "Admin",
    "InternalFLG": 0,
    "ActiveFLG": 1
  },
  {
    "PermissionID": 12,
    "PermissionNM": "Developer",
    "PermissionDSC": null,
    "GroupDSC": null,
    "InternalFLG": 1,
    "ActiveFLG": 0
  }
];