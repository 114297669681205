import React, { useState } from "react";
import { TextField, Box, Typography, Button, Grid } from "@mui/material";
import { ConnectorAPIService } from "../../../../config/api-service";

const CredentialForm = ({ data = [] }) => {
	const [formData, setFormData] = useState({});

	const handleSubmit = (event) => {
		event.preventDefault();
		console.log("Form Data:", formData);
	};

	const handleBlur = (event, key, connectorCredentialID) => {
		const { value } = event.target;
		setFormData((prev) => {
			const updatedData = {
				...prev,
				[key]: value,
			};

			// Prepare the updated DecryptedCredential object
			const updatedCredential = {
				...data.find(
					(item) =>
						item.ConnectorCredentialID === connectorCredentialID
				).DecryptedCredential,
				[key]: value,
			};
			ConnectorAPIService.updateTestUser(
				connectorCredentialID,
				updatedCredential
			)
				.then((result) => {
					console.log("Update successful:", result);
				})
				.catch((error) => {
					console.error("Error updating credential:", error);
				});
			console.log("updatedCredential", updatedCredential);

			return updatedData;
		});

		console.log(`${key}:`, value);
	};

	return (
		<>
            {data.map((item, index) => (
                <Box
                    key={index}
                    sx={{
                        border: "1px solid #ccc",
                        borderRadius: "8px",
                        padding: "16px",
                        margin: "16px 0",
                        position: "relative",
                    }}
                >
                    <Typography
                        component="legend"
                        sx={{
                            position: "absolute",
                            top: "-12px",
                            left: "16px",
                            fontSize: "14px",

                            backgroundColor: "white",
                            padding: "0 8px",
                        }}
                    >
                        {item.CredentialNM || "No Credential Name"}
                    </Typography>

                    <Grid container spacing={2} sx={{ marginTop: '5px' }}>
                        {Object.entries(item.DecryptedCredential || {}).map(
                            ([key, value]) => (
                                <Grid item xs={12} sm={6} key={key}>
                                    <TextField
                                        name={key}
                                        label={key}
                                        defaultValue={value}
                                        fullWidth
                                        onBlur={(e) =>
                                            handleBlur(
                                                e,
                                                key,
                                                item.ConnectorCredentialID
                                            )
                                        }
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        sx={{
                                            "& .MuiInputBase-root": {
                                                height: "36px",
                                            },
                                        }}
                                    />
                                </Grid>
                            )
                        )}
                    </Grid>
                </Box>
            ))
            }
		</>
	);
};

export default CredentialForm;
