import React, { Suspense, useContext, useEffect, useState } from "react";
import { Prompt, useHistory, useParams } from "react-router-dom";
import {
	Box,
	Button,
	Step,
	StepLabel,
	Stepper,
	Toolbar,
	Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Cookies } from "react-cookie-consent";
import {
	ConfigureAPIService,
	ConnectorAPIService,
	OrganizationAPIService,
	UserAPIService,
} from "../../config/api-service";
import { APP_KEY, ORGANIZATIONS } from "../../config/constants";
import MaterialUITheme from "../../config/material-ui-theme";
import { CommonLoaderContext } from "../../config/router";
import useScript from "../../config/useScript";
import Dialog from "../common/dialog";
import { ChooseConnectorComponent } from "./connect";
import HelpModal from "./Help";
import { ConfigureConnectorComponent } from "./load";
import { usePipelineContext } from "./pipeline.context";
import { CONNECTOR_REDUCER_TYPES } from "../../reducers/connectorReducer";
import { toast } from "react-toastify";
// import Help from "./Help2";

const selection = [null, "Today", "Yesterday", "Today Subtract", "Custom Date"];

const MapConnectorComponent = React.lazy(() => import("./map"));

const useStyles = makeStyles((theme) => ({
	root: {
		margin: "20px 0px 30px",
		padding: "0px 10px",
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
	},
	stepControlButtons: {},
	stepDisplay: {
		flex: 0.7,
	},
}));

function getSteps() {
	return ["Connect", "Map", "Load"];
}

export default function NewConnectorComponent(props) {
	const [, setCommonLoader] = useContext(CommonLoaderContext);
	const classes = useStyles();
	const steps = getSteps();
	const { id } = useParams();
	const history = useHistory();
	const [editDetailsLoaded, setEditDetailsLoaded] = useState(false);

	const {
		sourceState,
		destinationState,
		dispatchDestination,
		dispatchSource,
		getPreviewData,
		connectors,
		activeStep,
		jobID,
		scheduleID,
		jobName,
		mapDisabled,
		loadDisabled,
		deployDisabled,
		setActiveStep,
		setJobName,
		setMapDisabled,
		setExistingTable,
		setMethod,
		setFilterObject,
		setStartSelectionDR,
		setEndSelectionDR,
		setMinute,
		setTime,
		setWeekDay,
		setMonthDay,
		setMonth,
		setScheduleEnabled,
		setRunPeriod,
		setJobDataID,
		setJobID,
		setScheduleID,
		setDateRangeID,
		setNotificationID,
		setFailureFLG,
		setSuccessFLG,
		setEmail,
		setStartDaysFromToday,
		setEndDaysFromToday,
		setStartCustomDate,
		setEndCustomDate,
		getEditPayload,
		getConnections,
		testConnection,
		testConnectionWithFields,
	} = usePipelineContext();

	console.log("connectors", connectors);

	const getDetails = async (id) => {
		setCommonLoader(true);
		const {
			sourceObjectId,
			mapping = {},
			pipeline = {},
			job = {},
		} = await ConnectorAPIService.getPipelineDetails(id);

		const {
			SourceConnectorID,
			DestinationConnectorID,
			SourceCredentialsID,
			DestinationCredentialsID,
			PipelineNM,
		} = pipeline;
		const {
			destination_object_id,
			update_method_cd,
			filtered_column_nm,
			start_selection_nm,
			end_selection_nm,
			start_value_txt,
			end_value_txt,
			minute_selection_txt,
			time_selection_txt,
			week_day_selection_txt,
			month_day_selection_txt,
			month_selection_txt,
			enabled_flg,
			period_selection_txt,
			pipeline_mapping_id,
			pipeline_id,
			schedule_id,
			pipeline_date_range_id,
			notification_id,
			failure_flg,
			success_flg,
			email_txt,
		} = job;

		let options = JSON.parse(mapping?.PipelineObjectOptionsJSON);

		dispatchSource({
			type: CONNECTOR_REDUCER_TYPES.EDIT_PIPELINE_INIT,
			payload: {
				connectorId: SourceConnectorID,
				credentialId: SourceCredentialsID,
				selectedObject: sourceObjectId,
				fields: [],
				options: options,
			},
		});

		dispatchDestination({
			type: CONNECTOR_REDUCER_TYPES.EDIT_PIPELINE_INIT,
			payload: {
				connectorId: DestinationConnectorID,
				credentialId: DestinationCredentialsID,
				selectedObject: destination_object_id,
				mapping: JSON.parse(mapping?.PipelineMappingJSON),
			},
		});

		let sourceConnector = connectors.find(
			(con) => +con.ConnectorID === +SourceConnectorID
		);
		let destinationConnector = connectors.find(
			(con) => +con.ConnectorID === +DestinationConnectorID
		);

		if (!sourceConnector || !destinationConnector) {
			console.error(
				"Pipeline uses a connector that is now disabled. Renable connector id: ",
				!sourceConnector ? SourceConnectorID : DestinationConnectorID
			);
			toast.error("We are so sorry! An internal error has occurred.");
			setActiveStep(0);
			history.push("/");
			setCommonLoader(false);
			return;
		}

		if (!sourceConnector.SourceUploadFLG) {
			getConnections(SourceConnectorID, dispatchSource, false);
			testConnectionWithFields(
				SourceConnectorID,
				SourceCredentialsID,
				sourceObjectId,
				dispatchSource,
				options
			);
		}

		if (!destinationConnector.DestinationDownloadFLG) {
			getConnections(DestinationConnectorID, dispatchDestination, false);
			testConnection(
				DestinationConnectorID,
				DestinationCredentialsID,
				dispatchDestination
			);
		}

		getPreviewData({
			connectorId: SourceConnectorID,
			credentialId: SourceCredentialsID,
			selectedObject: sourceObjectId,
			mapping: JSON.parse(mapping?.PipelineMappingJSON),
			newOptions: options,
		});

		setCommonLoader(false);
		// dispatchSource({type: CONNECTOR_REDUCER_TYPES.SET_CREDENTIAL_ID, payload: {credentialId: SourceCredentialsID}});
		// dispatchDestination({type: CONNECTOR_REDUCER_TYPES.SET_CREDENTIAL_ID, payload: {credentialId: DestinationCredentialsID}})
		// dispatchSource({type: CONNECTOR_REDUCER_TYPES.SET_SELECTED_OBJECT, payload: {selectedObject: sourceObjectId}});
		// dispatchDestination({type: CONNECTOR_REDUCER_TYPES.SET_SELECTED_OBJECT, payload: {selectedObject: destination_object_id}});
		setExistingTable(false);

		// dispatchDestination({type: CONNECTOR_REDUCER_TYPES.SET_DESTINATION_MAPPING, payload: {mapping: JSON.parse(mapping?.PipelineMappingJSON)}});

		setJobName(PipelineNM);
		setMethod(update_method_cd);
		setFilterObject(filtered_column_nm);
		setStartSelectionDR(selection.indexOf(start_selection_nm));
		setEndSelectionDR(selection.indexOf(end_selection_nm));

		if (selection.indexOf(start_selection_nm) === 3) {
			setStartDaysFromToday(start_value_txt);
		} else if (selection.indexOf(start_selection_nm) === 4) {
			setStartCustomDate(start_value_txt);
		}

		if (selection.indexOf(end_selection_nm) === 3) {
			setEndDaysFromToday(end_value_txt);
		} else if (selection.indexOf(end_selection_nm) === 4) {
			setEndCustomDate(end_value_txt);
		}

		setMinute(minute_selection_txt);
		setTime(time_selection_txt);
		setWeekDay(week_day_selection_txt);
		setMonthDay(month_day_selection_txt);
		setMonth(month_selection_txt);
		setScheduleEnabled(!!enabled_flg);
		setRunPeriod(+period_selection_txt);
		setJobDataID(pipeline_mapping_id);
		setJobID(pipeline_id);
		setScheduleID(schedule_id);
		setDateRangeID(pipeline_date_range_id);
		setNotificationID(notification_id);
		setFailureFLG(!!failure_flg);
		setSuccessFLG(!!success_flg);
		setEmail(email_txt ? email_txt.split(",") : []);
		setEditDetailsLoaded(true);
	};

	const queryParams = new URLSearchParams(window.location.search);
	const from = queryParams.get("from");
	const verified = queryParams.get("verified");

	const submitOrganization = async (
		selectedOrganization = "",
		organizations = []
	) => {
		try {
			localStorage.setItem(
				ORGANIZATIONS,
				JSON.stringify({
					organizations,
					selectedOrganization,
					hasPMethods: false,
					organizationTRL: null,
				})
			);
			window.dispatchEvent(new Event("anonymousUserEventChange"));
		} catch {}
	};

	const submitOrganizationAfterVerified = async (
		selectedOrganization = "",
		organizations = []
	) => {
		try {
			const assignedOrganization =
				await OrganizationAPIService.assignUserToOrganization(
					selectedOrganization
				);

			const methods = await UserAPIService.getPaymentMethod();
			assignedOrganization &&
				methods &&
				localStorage.setItem(
					ORGANIZATIONS,
					JSON.stringify({
						organizations,
						selectedOrganization,
						hasPMethods:
							methods?.paymentMethods?.data?.length > 0
								? true
								: false,
						organizationTRL: assignedOrganization.organizationTRL,
					})
				);
			window.dispatchEvent(new Event("anonymousUserEventChange"));
		} catch {}
	};

	useEffect(() => {
		const loginAnonymously = async () => {
			try {
				OrganizationAPIService.getOrganizations()
					.then((res) => {
						localStorage.setItem(
							APP_KEY,
							JSON.stringify({
								user: res?.user,
								permissions: res?.permissionList,
								anonymousRegistered:
									!res?.user.UserAnonymousFlg,
							})
						);
						submitOrganization(
							res?.user?.DefaultOrganizationID,
							res?.organizationList
						);
					})
					.catch((err) => {});
			} catch (err) {}
		};
		if (from === "marketing" && parseInt(Cookies.get("anonymous"))) {
			loginAnonymously();
		}
	}, [from]);

	useEffect(() => {
		const verifyLogin = async () => {
			try {
				OrganizationAPIService.getOrganizations()
					.then((res) => {
						localStorage.setItem(
							APP_KEY,
							JSON.stringify({
								user: res?.user,
								permissions: res?.permissionList,
							})
						);
						submitOrganizationAfterVerified(
							res?.user?.DefaultOrganizationID,
							res?.organizationList
						);
					})
					.catch((err) => {});
			} catch (err) {}
		};
		if (verified === "true" && !parseInt(Cookies.get("anonymous"))) {
			verifyLogin();
		}
	}, []);

	useEffect(() => {
		if (from === "marketing" && parseInt(Cookies.get("anonymous"))) {
			localStorage.removeItem(APP_KEY);
			localStorage.removeItem(ORGANIZATIONS);
		}
	}, [from]);

	useEffect(() => {
		if (id && connectors.length && !editDetailsLoaded) {
			getDetails(id);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [connectors]);

	const handleNext = () => {
		if (activeStep === 3) {
			// Navigation to connectors
			history.push("/");
		} else {
			setActiveStep((prevActiveStep) => prevActiveStep + 1);
		}
	};

	const handleBack = () => {
		if (activeStep === 0) {
			// SET Connectors and Credentials back to null;
			dispatchSource({
				type: CONNECTOR_REDUCER_TYPES.SET_CONNECTOR_ID,
				payload: { connectorId: -1 },
			});
			dispatchDestination({
				type: CONNECTOR_REDUCER_TYPES.SET_CONNECTOR_ID,
				payload: { connectorId: -2 },
			});
			dispatchSource({
				type: CONNECTOR_REDUCER_TYPES.SET_CREDENTIAL_ID,
				payload: { credentialId: null },
			});
			dispatchDestination({
				type: CONNECTOR_REDUCER_TYPES.SET_CREDENTIAL_ID,
				payload: { credentialId: null },
			});
			setMapDisabled(true);

			// Navigate back to Connections
			history.push("/pipelines");
		} else if (activeStep === 1) {
			setActiveStep((prevActiveStep) => prevActiveStep - 1);
		} else {
			setActiveStep((prevActiveStep) => prevActiveStep - 1);
		}
	};

	const alertMsg = "You are about to lose your changes.";
	const handleLink = (label) => {
		switch (label) {
			case "Connect":
				if (activeStep > 0) {
					setActiveStep(0);
				}
				break;
			case "Map":
				if (activeStep > 1) {
					setActiveStep(1);
				} else if (activeStep < 1) setActiveStep(1);
				break;
			case "Load":
				if (activeStep < 2) setActiveStep(2);
				break;
			default:
				break;
		}
	};

	const handleDiscard = () => {
		if (jobID) {
			ConfigureAPIService.deleteJob(jobID).then((res) => {
				history.push("/");
			});
		} else {
			history.push("/");
		}
	};

	const handleDeploy = () => {
		// FIRST Validate:
		// Job Name
		// Schedule
		// Connector

		if (
			jobName &&
			scheduleID &&
			sourceState.connectorId > -1 &&
			destinationState.connectorId > -1
		) {
			setIsBlocking(false);
			ConfigureAPIService.postScheduleTask(jobID).then((res) => {
				history.push("/");
			});
		} else {
			if (!jobName) {
				window.alert("Please add a pipeline name.");
			} else if (
				!sourceState.connectorId ||
				!destinationState.connectorId
			) {
				window.alert("Please configure a source and a destination.");
			} else {
				window.alert("Unable to deploy job.");
			}
		}
	};

	const handleSaveChanges = () => {
		if (
			jobName &&
			scheduleID &&
			sourceState.connectorId > -1 &&
			destinationState.connectorId > -1
		) {
			setIsBlocking(false);
			let payload = getEditPayload();
			ConfigureAPIService.putPipelineChanges(payload, jobID).then(
				(res) => {
					ConfigureAPIService.postScheduleTask(jobID).then((res) => {
						history.push("/");
					});
				}
			);
		} else {
			if (!jobName) {
				window.alert("Please add a pipeline name.");
			} else if (
				!sourceState.connectorId ||
				!destinationState.connectorId
			) {
				window.alert("Please configure a source and a destination.");
			} else {
				window.alert("Unable to deploy job.");
			}
		}
	};

	const getStepContent = (stepIndex) => {
		switch (stepIndex) {
			case 0:
				return <ChooseConnectorComponent />;
			case 1:
				return (
					<Suspense
						fallback={
							<Box
								sx={{
									height: "60vh",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<div>Loading...</div>
							</Box>
						}
					>
						<MapConnectorComponent />
					</Suspense>
				);
			case 2:
				return <ConfigureConnectorComponent />;
			default:
				history.push("/");
		}
	};

	const [isBlocking, setIsBlocking] = useState(false);
	useEffect(() => {
		if (activeStep > 0) setIsBlocking(true);
	}, [activeStep]);

	useScript();

	const [discardConfirmDialog, setDiscardConfirmDialog] = useState(false);
	const [helpModal, setHelpModal] = useState(false);

	const handleDiscardChanges = () => {
		dispatchSource({
			type: CONNECTOR_REDUCER_TYPES.SET_CONNECTOR_ID,
			payload: { connectorId: null },
		});
		dispatchDestination({
			type: CONNECTOR_REDUCER_TYPES.SET_CONNECTOR_ID,
			payload: { connectorId: null },
		});

		dispatchSource({
			type: CONNECTOR_REDUCER_TYPES.SET_CREDENTIAL_ID,
			payload: { credentialId: null },
		});
		dispatchDestination({
			type: CONNECTOR_REDUCER_TYPES.SET_CREDENTIAL_ID,
			payload: { credentialId: null },
		});
		dispatchSource({
			type: CONNECTOR_REDUCER_TYPES.SET_SELECTED_OBJECT,
			payload: { selectedObject: null },
		});
		dispatchDestination({
			type: CONNECTOR_REDUCER_TYPES.SET_SELECTED_OBJECT,
			payload: { selectedObject: null },
		});
		setExistingTable(false);
		dispatchDestination({
			type: CONNECTOR_REDUCER_TYPES.SET_DESTINATION_MAPPING,
			payload: { mapping: [] },
		});
		setJobName("");
		setMethod(2);
		setFilterObject("");
		setStartSelectionDR(0);
		setEndSelectionDR(0);
		setMinute(0);
		setTime("00:00");
		setWeekDay(1);
		setMonthDay(1);
		setMonth(1);
		setScheduleEnabled(true);
		setRunPeriod(1);
		setJobDataID(null);
		setJobID(null);
		setScheduleID(null);
		setDateRangeID(null);
		setNotificationID(null);
		setFailureFLG(true);
		setSuccessFLG(true);
		setEmail([]);

		history.push("/pipelines");
	};

	return (
		<MaterialUITheme>
			{!!id && (
				<Toolbar
					variant="dense"
					sx={{
						display: "flex",
						justifyContent: "center",
						backgroundColor: "#FF9393",
						minHeight: 30,
						my: 1,
						"&.MuiToolbar-root": { pl: 25 },
					}}
				>
					<Typography
						component="div"
						sx={{ ml: "auto", color: "#fff" }}
					>
						Currently editing {!!jobName && `"${jobName || ""}"`}{" "}
						Pipeline
					</Typography>
					<Box sx={{ ml: "auto" }}>
						<Button
							onClick={() => {
								setIsBlocking(false);
								setDiscardConfirmDialog(true);
							}}
							variant="contained"
							disableElevation
							sx={{
								backgroundColor: "#fff",
								color: "#FF9393",
								py: 0,
								px: 0.75,
								mr: 1.5,
							}}
						>
							Discard changes
						</Button>
						<Button
							onClick={handleSaveChanges}
							variant="contained"
							disableElevation
							sx={{
								backgroundColor: "#fff",
								color: "#FF9393",
								py: 0,
								px: 0.75,
							}}
						>
							Save
						</Button>
					</Box>
				</Toolbar>
			)}

			<Box
				sx={{
					margin: "20px 0px 30px",
					padding: "0px 10px",
					display: "flex",
					alignItems: "center",
					justifyContent: { xs: "center", sm: "space-between" },
				}}
			>
				{!id && activeStep === steps.length - 1 ? (
					<Button
						variant="outlined"
						color="primary"
						size="small"
						onClick={handleBack}
						className={classes.stepControlButtons}
						sx={{ px: "9px", display: { xs: "none", sm: "block" } }}
					>
						Previous
					</Button>
				) : (
					<Button
						variant="outlined"
						color="primary"
						size="small"
						onClick={handleBack}
						className={classes.stepControlButtons}
						sx={{ px: "9px" }}
					>
						{activeStep === 0 ? "Cancel" : "Previous"}
					</Button>
				)}

				<Stepper
					className={classes.stepDisplay}
					activeStep={activeStep}
					alternativeLabel
				>
					{steps.map((label) => (
						<Step key={label}>
							<StepLabel
								sx={{
									"& .MuiStepLabel-label": {
										mt: "0 !important",
									},
								}}
							>
								<Typography
									onClick={() => handleLink(label)}
									className={
										label === "Map" && mapDisabled
											? "step-link disabled-link"
											: label === "Load" && loadDisabled
											? "step-link disabled-link"
											: "step-link"
									}
								>
									<Typography
										sx={{
											fontSize: {
												xs: "12px",
												sm: "16px",
											},
										}}
									>
										{label}
									</Typography>
								</Typography>
							</StepLabel>
						</Step>
					))}
				</Stepper>

				{!id && activeStep === steps.length - 1 ? (
					<div style={{ display: "flex", gap: 15 }}>
						<Button
							variant="outlined"
							color="primary"
							size="small"
							className={classes.stepControlButtons}
							onClick={handleDiscard}
							sx={{ display: { xs: "none", sm: "block" } }}
						>
							{" "}
							{/*TODO: ADD DISABLED LOGIC*/}
							Discard
						</Button>
						<Button
							disabled={deployDisabled}
							variant="contained"
							color="primary"
							size="small"
							className={classes.stepControlButtons}
							onClick={handleDeploy}
							sx={{ display: { xs: "none", sm: "block" } }}
						>
							Deploy
						</Button>
					</div>
				) : id && activeStep === steps.length - 1 ? (
					<div />
				) : (
					<>
						<Button
							variant="contained"
							color="primary"
							size="small"
							className={classes.stepControlButtons}
							onClick={handleNext}
							disabled={
								activeStep === 0
									? mapDisabled
									: activeStep === 1
									? loadDisabled
									: false
							}
						>
							{" "}
							{/*TODO: ADD DISABLED LOGIC*/}
							Next
						</Button>
					</>
				)}
			</Box>

			{!id && activeStep === steps.length - 1 && (
				<Box
					sx={{
						display: "flex",
						gap: 5,
						mt: "-25px",
						mb: "10px",
						justifyContent: "center",
					}}
				>
					<Button
						variant="outlined"
						color="primary"
						size="small"
						className={classes.stepControlButtons}
						onClick={handleDiscard}
						sx={{ display: { xs: "inline-block", sm: "none" } }}
					>
						{" "}
						{/*TODO: ADD DISABLED LOGIC*/}
						Discard
					</Button>
					<Button
						disabled={deployDisabled}
						variant="contained"
						color="primary"
						size="small"
						className={classes.stepControlButtons}
						onClick={handleDeploy}
						sx={{ display: { xs: "inline-block", sm: "none" } }}
					>
						Deploy
					</Button>
				</Box>
			)}

			<div>{getStepContent(activeStep)}</div>
			<Box
				sx={{
					py: "5px",
					px: { xs: "15px", sm: "27px", md: "40px" },
					fontSize: { xs: "16px", sm: "18px", md: "20px" },
					right: { xs: "-20px", sm: "-33px", md: "-50px" },
				}}
				className="help-button"
				onClick={() => setHelpModal(true)}
			>
				Help
			</Box>
			{/* <Dialog
				open={helpModal}
				handleClose={() => setHelpModal(false)}
				title={activeStep === 0 ? "Connect" : activeStep === 1 ? "Map" : activeStep === 2 ? "Load" : ""}
				centerAligned={false}
				maxWidth="lg"
			>
				<Help videoUrl={activeStep === 0 ? "https://www.youtube.com/embed/JLBLJqUxoWU" : activeStep === 1 ? "https://www.youtube.com/embed/vZAF_vKxgTo" : activeStep === 2 ? "https://www.youtube.com/embed/q3MY3cpFd9s" : ""} />
				<div style={{ padding: 0 }}>
					<p style={{ padding: 0, margin: 0 }}>Support:</p>
					<p style={{ padding: 0, margin: 0, wordWrap: "break-word" }}>Email: support@dataconnector.com</p>
					<p style={{ padding: 0, margin: 0 }}>Phone: 435-299-1131</p>
				</div>
			</Dialog> */}
			<HelpModal
				activeStep={activeStep}
				showModal={helpModal}
				setShowModal={setHelpModal}
			/>

			<Dialog
				open={discardConfirmDialog}
				handleClose={() => {
					setIsBlocking(true);
					setDiscardConfirmDialog(false);
				}}
				title="Confirm Discard"
				handleConfirm={handleDiscardChanges}
			>
				You are about to lose your changes. Are you sure to procceed?
			</Dialog>

			<Prompt when={isBlocking} message={(location) => alertMsg} />
		</MaterialUITheme>
	);
}
